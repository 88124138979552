<template>
  <section class="container">
    <h2>合作案例</h2>
    <div class="customerWrapper">
      <img v-for="(img,index) in cooperationList" :key="`合作案例${index+1}`" :src="img" :alt="`合作案例${index+1}`">
    </div>
  </section>
</template>

<script>
export default {
  name: "customer",
  data(){
    return {
      cooperationList: [
        require('@/assets/img/About/company_caseone.svg'),
        require('@/assets/img/About/company_casetwo.svg'),
        require('@/assets/img/About/company_casethree.svg'),
        require('@/assets/img/About/company_casefour.svg'),
        require('@/assets/img/About/company_casefive.svg'),
        require('@/assets/img/About/company_casesix.svg'),
        require('@/assets/img/About/company_caseseven.svg'),
        require('@/assets/img/About/company_caseeight.svg'),
        require('@/assets/img/About/company_casenine.png'),
        require('@/assets/img/About/company_caseten.svg'),
        require('@/assets/img/About/company_caseelven.png'),
        require('@/assets/img/About/company_casetwelve.png'),
        require("@/assets/img/About/company_thirteen.svg/"),
        require("@/assets/img/About/company_fourteen.png/"),
        require("@/assets/img/About/company_fifteen.png/"),
        require("@/assets/img/About/company_sixteen.svg/"),
        require("@/assets/img/About/company_seventeen.svg/"),
        require("@/assets/img/About/company_eighteen.svg/"),
        require("@/assets/img/About/company_nineteen.png/")
      ],
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.container {
  padding: 20px;
}

.title {
  margin-bottom: 16px;
}

.customerWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 16px;
  >img{
    width: 48%;
    margin-bottom: 16px;
  }
}

</style>