<template>
  <div class="mobileSectionTitle">
    <img src="@/assets/img/home_tag(1).svg" alt="leftTagIcon">
    <h2 class="name"><slot></slot></h2>
    <img src="@/assets/img/home_tag.svg" alt="rightTagIcon">
  </div>
</template>

<script>
export default {
  name: "MobileSectionTitle"
}
</script>

<style scoped lang="scss">
.mobileSectionTitle{
  display: flex;
  justify-content: center;
  align-items: center;

  >h2.name{
    padding: 0 8px;
  }

  >img{
    width: 16px;
  }
}
</style>