<template>
  <div id="mobileApp">
    <MobileHeader/>
    <div class="appContainer">
      <router-view/>
    </div>
  </div>
</template>


<script>
import MobileHeader from '@/components/mobile/public/MobileHeader.vue'
// @ is an alias to /src
export default {
  name: 'MobileApp',
  components: {MobileHeader}
}
</script>

<style lang="scss">
#mobileApp {
  box-sizing: border-box;
  font-family: -apple-system, "Noto Sans", "Helvetica Neue", Helvetica, "Nimbus Sans L", Arial, "Liberation Sans", "PingFang SC", "Hiragino Sans GB", "Noto Sans CJK SC", "Source Han Sans SC", "Source Han Sans CN", "Microsoft YaHei", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti", SimHei, "WenQuanYi Zen Hei Sharp", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #373636;
  // 消除移动端点击时出现的蓝色背景
  -webkit-tap-highlight-color: transparent;
  min-width: 350px;
}

.appContainer {
  margin: 0 auto;
  max-width: 800px;
  // 68px为页头高度
  height: calc(100vh - 68px);
  overflow: scroll;
}
</style>