<template>
  <div>
    <main :is="renderName"/>
  </div>
</template>

<script>
import BasicAccounting from '@/views/Courses/BasicAccounting.vue'
import CrossBorderExportEcommerceJourney from '@/views/Courses/CrossBorderExportEcommerceJourney.vue'
import InternationalFreightForwarderPractice from '@/views/Courses/InternationalFreightForwarderPractice.vue'
import InternationalTradePractice from '@/views/Courses/InternationalTradePractice.vue'
import MakingForeignTradeDocuments from '@/views/Courses/MakingForeignTradeDocuments.vue'

export default {
  name: "CourseDetail",
  components: {
    BasicAccounting,
    CrossBorderExportEcommerceJourney,
    InternationalFreightForwarderPractice,
    InternationalTradePractice,
    MakingForeignTradeDocuments
  },
  computed: {
    renderName(){
      return this.$route.params.name
    }
  },
}
</script>

<style scoped lang="scss">

</style>