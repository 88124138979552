<template>
  <div>
    <nav class="navList">
      <router-link class="navItem" active-class="activeNav"
                   v-for="nav in navList" :key="nav.name"
                   :to="`/digitalPlatform/${nav.path}`" replace>
        {{ nav.name }}
      </router-link>
    </nav>
    <Content/>
  </div>
</template>

<script>
import Content from '@/components/mobile/digitalPlatformContent.vue'

export default {
  name: "DigitalPlatform",
  components: {Content},
  data(){
    return {
      navList: [
        {
          name: '行企动态',
          path: "industryDynamics"
        },
        {
          name: '课程建设',
          path: "courseBuilding"
        },
        {
          name: '教学备课',
          path: "teachingPreparation"
        },
        {
          name: '课堂教学',
          path: "teaching"
        },
        {
          name: '学生学习',
          path: "learning"
        },
        {
          name: '教学互动',
          path: "teachingInteraction"
        },
        {
          name: '课堂实训',
          path: "training"
        },
        {
          name: '作业试卷',
          path: "schoolworkTest"
        },
        {
          name: '考核评价',
          path: "assessment"
        },
        {
          name: '数据中心',
          path: "data"
        },

      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.navList {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: scroll;
  background: rgba($theme-color, 0.02);
  padding: 16px 20px 8px;
  justify-content: flex-start;
  align-items: center;

  .navItem {
    flex-shrink: 0;
    color: $gray;
    width: 25%;
    max-width: 120px;
    margin-bottom: 16px;
    transform: translateY(8px);
    text-align: center;

    &.activeNav {
      color: $theme-color
    }
  }
}
</style>