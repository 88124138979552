<template>
  <div class="wrapper">
    <img src="../../assets/img/Courses/MakingForeignTradeDocuments_1.jpg" alt="MakingForeignTradeDocuments_1">
    <img src="../../assets/img/Courses/MakingForeignTradeDocuments_2.jpg" alt="MakingForeignTradeDocuments_2">
  </div>
</template>

<script>
export default {
  name: "MakingForeignTradeDocuments"
}
</script>

<style scoped lang="scss">
.wrapper{
  img{
    width: 100%;
  }
}
</style>
