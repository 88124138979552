<template>
  <div class="itemList">
    <div class="item" v-for="item in itemList" :key="item.title">
      <img :src='item.imageLink' :alt="item.title">
      <h4>{{ item.title }}</h4>
      <p class="description">{{ item.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "technicalServices",
  data(){
    return {
      itemList: [
        {
          imageLink: require('../../../assets/img/p&s-plat1.jpg'),
          title: '在线开放课程建设',
          description: '利用网络信息技术推进教学改革，促进优质教学资源共享，结合教师与从业者丰富的经验完成数字化课程资源的制作与在线实训的开发。'
        },
        {
          imageLink: require('../../../assets/img/p&s-plat2.jpg'),
          title: '精品课程建设',
          description: '定制课程目标，深化教学内容与体系的研究方案，聚集更加优秀的教育资源，从而提高课程教学质量。'
        },
        {
          imageLink: require('../../../assets/img/p&s-plat3.jpg'),
          title: '微课建设',
          description: '依据教育工作者与学习者的特征组织和编排课程内容，采用多种手段制作课程资源，突破传统知识型的静态资源。'
        },
        {
          imageLink: require('../../../assets/img/p&s-plat4.jpg'),
          title: '金课建设',
          description: '与多所高校合作建设具有高阶性、创新性、挑战度的线上、线下、线上线下混合式、虚拟仿真和社会实践各类型的课程。'
        },
      ]
    }

  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.itemList {
  display: flex;
  flex-wrap: wrap;
}

.item {
  box-sizing: border-box;
  margin: 8px 12px;
  width: calc(50% - (2 * 12px));

  > img {
    width: 100%;
    border-radius: 6px;
  }

  > h4 {
    padding: 8px 0;
    text-align: center;
    font-size: 15px;
  }

  > p.description {
    text-indent: 2em;
    font-size: 12px;
    line-height: 1.4;
    color: $text-gray;
  }
}
</style>