<template>
  <div class="title">
    <img src="../assets/img/home_tag(1).svg" alt="leftTagIcon">
    <h2>{{ titleName }}</h2>
    <img src="../assets/img/home_tag.svg" alt="rightTagIcon">
  </div>
</template>

<script>

export default {
  name: "title",
  props:['titleName'],
}
</script>

<style lang="scss" scoped>
.title{
  font-family: MicrosoftYaHeiSemibold,"Microsoft YaHei UI",serif;
  display: flex;
  font-size: 2.5vw;
  color: #373636;
  justify-content: center;
  padding-bottom: 3vw;
  h2{
    font-weight: normal ;
    padding: 0 0.8vw;
  }
  img{
    width: 2vw;
  }
}
@media (min-width: 1229px)  {
  .title{
    font-size:24px;
    padding-bottom: 25px;
    h2{
      padding: 0 14px;
    }
    img{
      width: 26px;
    }
  }
}
</style>