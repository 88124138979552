<template>
  <div class="solution">
    <Title title-name="解决方案"></Title>
    <ul class="solutionList">
      <li>
        <img src="../../assets/img/平台建设方案.svg" alt="平台建设方案Icon">
        <h3>平台建设方案</h3>
        <p>
          实现教育现代化离不开教育信息化，信息化要服务于教育现代化。教学信息化平台建设以学习者为中心，构建“教”与“学”过程。同时构建能够满足教学资源建设长期持续发展的应用框架，实现平台支撑的集中化。教师应用平台可建设课程资源，开展教学管理，备课授课、指导学生学习、批改作业及交流答疑等教学活动。线上线下相结合的混合教学模式，建成时时可教、处处能教的教学场景。</p>
        <router-link target="_blank" class="more" to="/solutions/0">
          <span>了解更多</span>
          <i class="el-icon-right"></i>
        </router-link>
      </li>
      <li>
        <img src="../../assets/img/课程建设方案.svg" alt="课程建设方案Icon">
        <h3>课程建设方案</h3>
        <p>
          实现教育现代化离不开教育信息化，信息化要服务于教育现代化。信息化课程建设以学习者为中心，构建教学资源和教学过程。根据教育部关于印发《教育信息化2.0行动计划》的通知要实现从专用资源向大资源转变；从提升…生信息技术应用能力、向提升信息技术素养转…</p>
        <router-link target="_blank" class="more" to="/solutions/1">
          <span>了解更多</span>
          <i class="el-icon-right"></i>
        </router-link>
      </li>
    </ul>
  </div>

</template>

<script>
import Title from '../Title.vue'

export default {
  name: "Solution",
  components: {Title},
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.solution {
  padding: 8vw 0;
  background: rgba($theme-color, 0.02);
}

.solutionList {
  //border: 1px solid red;
  margin: 1vw auto;
  width: 85vw;
  display: flex;
  justify-content: space-between;

  li {
    //border: 1px solid blue;
    padding: 6%;
    box-shadow: 0 0 40px #ececec;
    background: white;
    width: 30vw;
    height: 30vw;
    border-radius: 1vw;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
  }
}

.solutionList > li {
  img {
    height: 28%;
  }

  h3 {
    //font-size: 28px;
    font-size: 200%;
    color: #373636;
    padding-top: 6%;
  }

  p {
    margin: 6% 0;
    font-size: 110%;
    font-family: "Microsoft YaHei UI", sans-serif;
    color: #5B5B5B;
    line-height: 1.9em;
    //下面这四行作用，多行文本超出4行显示省略号
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }

  .more {
    color: $theme-color;

    span {
      padding-right: 0.5vw;
    }
  }
}

@media(min-width: 1229px) {
  .solution {
    padding: 110px 0;
  }
  .solutionList {
    margin: 60px auto 20px auto;
    width: 1150px;

    li {
      width: 390px;
      height: 390px;
      border-radius: 15px;
    }
  }
}
</style>