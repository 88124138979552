import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from "../views/About.vue";
import Solutions from "../views/Solutions";
import InternationalTradePractice from  "../views/Courses/InternationalTradePractice"
import InternationalFreightForwarderPractice from "../views/Courses/InternationalFreightForwarderPractice";
import CrossBorderExportEcommerceJourney from "../views/Courses/CrossBorderExportEcommerceJourney";
import MakingForeignTradeDocuments from "../views/Courses/MakingForeignTradeDocuments";
import BasicAccounting from "../views/Courses/BasicAccounting";

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/home'
  },
  {
    path:'*',
    redirect:'/home',
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/about',
    component: About
  },
  {
    path:'/solutions/:index',
    component: Solutions
  },
  {
    path:'/courses/InternationalTradePractice',
    component: InternationalTradePractice
  },
  {
    path:'/courses/InternationalFreightForwarderPractice',
    component: InternationalFreightForwarderPractice
  },
  {
    path:'/courses/CrossBorderExportEcommerceJourney',
    component: CrossBorderExportEcommerceJourney
  },
  {
    path:'/courses/MakingForeignTradeDocuments',
    component: MakingForeignTradeDocuments
  },
  {
    path:'/courses/BasicAccounting',
    component: BasicAccounting
  },
]

const router = new VueRouter({
  routes
})

export default router
