<template>
<div class="digitalClass">
  <p class="description"> 云指课堂是基于云平台、大数据，通过任意终端实现的理实一体化智能课堂。云指课堂通过组织有经验的一线教师及相关行业、企业人员及技术研发团队，对多种形式的教学资源进行有序梳理和有效组织，将行业企业的工作任务及工作流程结合教学目标，把学生必须要掌握的知识点、技能点浓缩落实到课堂，解决教师备课难题，促进教师灵活组织教学。</p>
  <div class="carouselWrap">
    <el-carousel height="40vw" :autoplay="false" trigger="click" arrow="never">
      <el-carousel-item>
        <ul class="courseList">
          <li>
            <img src="../../../assets/img/p&s-course1.jpg" alt="p&s-course1">
            <main>
              <div class="title">
                <h4>International Trade Practice</h4>
                <h2>国际贸易实务</h2>
              </div>
              <p>
                课程选取真实进出口贸易业务资料为案例，以进出口货物贸易的工作流程为主线，组织国际货物贸易的专业知识、基本理论的学习和实务操作技能的训练。              </p>
              <router-link to="/courses/InternationalTradePractice" class="more" target="_blank">
                <span>了解更多</span>
                <i class="el-icon-right"></i>
              </router-link>
            </main>
          </li>
          <li>
            <img src="../../../assets/img/p&s-course2.jpg" alt="p&s-course2">
            <main>
              <div class="title">
                <h4>International freight forwarder practice</h4>
                <h2>国际货运代理实务</h2>
              </div>
              <p>
                课程从货代岗位职责出发，详细讲解国际货运运输及国际货运代理的理论知识，阐述揽货、委托代理、订舱、报关、装船出货等业务的操作。
              </p>
              <router-link to="/courses/InternationalFreightForwarderPractice" class="more" target="_blank">
                <span>了解更多</span>
                <i class="el-icon-right"></i>
              </router-link>
            </main>
          </li>
          <li>
            <img src="../../../assets/img/p&s-course5.jpg" alt="p&s-course5">
            <main>
              <div class="title">
                <h4>Cross-border export e-commerce journey</h4>
                <h2>跨境出口电商之旅</h2>
              </div>
              <p>
                本课程以 Amazon 为平台，以实际商品的出口贸易为例，详细介绍跨境电商涉及的供应链、物流、空运、海关、资金流等知识。从了解各大电商平台到熟悉各大平台特点，从商品市场调研到商品的运营和销售，其中涉及平台的站点介绍、入驻流程、收费标准，商品的成本分析、定价，产品资料、关键字的编辑，风险控制，后台数据分析，客服管理，营销和推广，流量的提升等各方面知识的阐述。
              </p>
              <router-link to="/courses/CrossBorderExportEcommerceJourney" class="more" target="_blank">
                <span>了解更多</span>
                <i class="el-icon-right"></i>
              </router-link>
            </main>
          </li>
        </ul>
      </el-carousel-item>
      <el-carousel-item>
        <ul class="courseList">
          <li>
            <img src="../../../assets/img/p&s-course3.jpg" alt="p&s-course3">
            <main>
              <div class="title">
                <h4>Making foreign trade documents</h4>
                <h2>外贸单证制作</h2>
              </div>
              <p>
                课程结合企业真实业务，详细讲解对外贸易中所涉及的单据和文件，讲述资金单据、商业单据、货运单据、保险单据、原产地...
              </p>
              <router-link to="/courses/MakingForeignTradeDocuments" class="more" target="_blank">
                <span>了解更多</span>
                <i class="el-icon-right"></i>
              </router-link>
            </main>
          </li>
          <li>
            <img src="../../../assets/img/p&s-course4.jpg" alt="p&s-course4" >
            <main>
              <div class="title">
                <h4>Basic accounting</h4>
                <h2>基础会计</h2>
              </div>
              <p>
                课程以企业真实业务为案例，结合最新的法律法规及会计准则，详细讲述会计专业涉及到的岗位，业务流转。
              </p>
              <router-link to="/courses/BasicAccounting" class="more" target="_blank">
                <span>了解更多</span>
                <i class="el-icon-right"></i>
              </router-link>
            </main>
          </li>
          <li style="opacity: 0;"></li>
        </ul>
      </el-carousel-item>
    </el-carousel>
  </div>
</div>
</template>

<script>
export default {
  name: "DigitalClass"
}
</script>

<style lang="scss" scoped>
@import "~@/assets/Variable.scss";
.digitalClass{
  width: 90vw;
  margin: 0 auto;
}
.description{
  text-indent: 2em;
  font-size: 1.1vw;
  text-align: left;
  color:#5B5B5B;
  padding-bottom: 2vw;
  line-height: 1.8em;
}
.carouselWrap{
  margin: 0 auto;
  min-width: 799px;
}
.courseList{
  display: flex;
  justify-content: space-around;
  height: 90%;
  min-width: 799px;
  li{
    box-shadow: 0 0 20px #e3e3e3;
    border-radius: 1.2vw;
    width: 30%;
    overflow: hidden;
    text-align: left;
    img{
      width: 100%;
    }
    main{
      padding: 2vw;
    }
  }
}
main{
  .title{
    h4{
      color:#FA7A1E;
      font-size: 10%;
      transform: scale(0.8) translateX(-12%);
      white-space:nowrap;
      text-align: left;
    }
    h2{
      font-size: 150%;
      letter-spacing:0.1em;
      padding-top:2%;
    }
  }
  p{
    font-size: 95%;
    font-family:  "Microsoft YaHei UI",sans-serif;
    color: #5B5B5B;
    line-height: 160%;
    margin: 6% 0;
    //下面这四行作用，多行文本超出3行显示省略号
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .more{
    font-size: 1vw;
    color: $theme-color;
    span{
      padding-right: 3%;
    }
  }
}


// 对轮播图指示器的样式进行修改
::v-deep .el-carousel__indicator{
  padding: 1vw;
  .el-carousel__button{
    border-radius: 50%;
    background: #cccccc;
    height: 0.8vw;
    width: 0.8vw;
  }
}
::v-deep .is-active{
  .el-carousel__button{
    background: $theme-color;
  }
}
@media(min-width: 1229px){
  .description{
    text-indent: 2em;
    font-size: 13px;
    padding-bottom: 24px;
    line-height: 1.8em;
    width: 1100px;
    margin: 0 auto;
  }
  .carouselWrap{
    margin: 0 auto;
    width: 1120px;
  }
  .el-carousel{
    height: 520px;
    overflow: hidden;
  }
  .courseList{
    li{
      border-radius: 10px;
      height: 450px;
      main{
        padding: 20px;
      }
    }
  }
  main{
    p{
      line-height: 1.8em;
    }
    .more{
    font-size: 14px;
  }
  }
  // 对轮播图指示器的样式进行修改
  ::v-deep .el-carousel__indicator{
    padding: 12px;
    .el-carousel__button{
      height: 10px;
      width: 10px;
    }
  }
}
</style>
