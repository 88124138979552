<template>
  <div class="product">
    <Title title-name="产品服务"></Title>
    <div class="buttonList">
      <button v-for="(item,index) in navItems" :key="index" @click="currentComponent = item.component"
              :class="{selected: currentComponent === item.component}">{{ item.name }}
      </button>
    </div>
    <main :is="currentComponent"></main>
  </div>
</template>

<script>
import Title from '../Title.vue'
import DigitalClass from "./Product/DigitalClass";
import InformationPlatform from "./Product/InformationPlatform";
import QualityPlatform from "./Product/QualityPlatform";
import TechnicalServices from "./Product/TechnicalServices";

export default {
  name: "Product",
  components: {
    TechnicalServices,
    InformationPlatform,
    DigitalClass,
    QualityPlatform,
    Title
  },
  data() {
    return {
      currentComponent: 'DigitalClass',
      navItems: [
        {name: '云指课堂数字化课程', component: 'DigitalClass'},
        {name: '融合型数字化教学平台', component: 'InformationPlatform'},
        {name: '教学质量管理平台', component: 'QualityPlatform'},
        {name: '技术服务支撑', component: 'TechnicalServices'},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/Variable.scss";

.product {
  padding: 8vw 0;
}

.buttonList {
  margin: 0 auto;
  padding: 3vw 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: MicrosoftYaHeiSemibold, "Microsoft YaHei UI", serif;

  button {
    border-radius: 5vw;
    padding: 1vw 4%;
    width: 26%;
    min-width: 165px;
    margin: 0 10px;
    font-size: 90%;
    background: #FCFCFD;
    border: 1px solid #EBEBEB;
    color: #373636;
    cursor: pointer;
    font-weight: bold;
  }

  button.selected {
    background: rgba($theme-color, 0.06);
    color: $theme-color;
    border: none;
  }
}

@media(min-width: 1229px) {
  .product {
    padding: 80px 0;
  }
  .buttonList {
    padding: 30px 0;
    width: 1100px;

    button {
      border-radius: 30px;
      padding: 13px 4%;
      font-size: 15px;
    }
  }
}
</style>