<template>
  <div class="digitalClass">
    <div class="description">云指课堂是基于云平台、大数据，通过任意终端实现的理实一体化智能课堂。</div>
    <div class="courseList">
      <div class="course" v-for="course in courseList" :key="course.key">
        <img class="cover" :src="require(`@/assets/img/${course.coverName}`)" :alt="course.title">
        <main class="content">
          <div class="title">
            <h4>{{ course.subtitle }}</h4>
            <h2>{{ course.title }}</h2>
          </div>
          <p>{{ course.description }}</p>
          <router-link :to="`/course/${course.key}`" class="more">
            <span>了解更多</span>
            <i class="el-icon-right"/>
          </router-link>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "digitalClass",
  data(){
    return {
      courseList: [
        {
          key: "internationalTradePractice",
          title: '国际贸易实务',
          subtitle: 'International trade practice',
          coverName: "p&s-course1.jpg",
          description: "课程选取真实进出口贸易业务资料为案例，以进出口货物贸易的工作流程为主线，组织国际货物贸易的专业知识、基本理论的学习和实务操作技能的训练。",
        },
        {
          key: "internationalFreightForwarderPractice",
          title: '国际货运代理实务',
          subtitle: 'International freight forwarder practice',
          coverName: "p&s-course2.jpg",
          description: "课程从货代岗位职责出发，详细讲解国际货运运输及国际货运代理的理论知识，阐述揽货、委托代理、订舱、报关、装船出货等业务的操作。",
        },
        {
          key: "crossBorderExportEcommerceJourney",
          title: '跨境出口电商之旅',
          subtitle: 'Cross-border export e-commerce journey',
          coverName: "p&s-course5.jpg",
          description: "本课程以 Amazon 为平台，以实际商品的出口贸易为例，详细介绍跨境电商涉及的供应链、物流、空运、海关、资金流等知识。从了解各大电商平台到熟悉各大平台特点，从商品市场调研到商品的运营和销售，其中涉及平台的站点介绍、入驻流程、收费标准，商品的成本分析、定价，产品资料、关键字的编辑，风险控制，后台数据分析，客服管理，营销和推广，流量的提升等各方面知识的阐述。",
        },
        {
          key: "makingForeignTradeDocuments",
          title: '外贸单证制作',
          subtitle: 'Making foreign trade documents',
          coverName: "p&s-course3.jpg",
          description: "课程结合企业真实业务，详细讲解对外贸易中所涉及的单据和文件，讲述资金单据、商业单据、货运单据、保险单据、原产地...",
        },
        {
          key: "basicAccounting",
          title: '基础会计',
          subtitle: 'Basic accounting',
          coverName: "p&s-course4.jpg",
          description: "课程以企业真实业务为案例，结合最新的法律法规及会计准则，详细讲述会计专业涉及到的岗位，业务流转。",
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/Variable.scss";

.digitalClass {
  .description {
    color: $text-gray;
    text-indent: 2em;
    line-height: 1.8;
    margin-bottom: 16px;
  }
}

.courseList {
  display: flex;
  width: 100%;
  overflow: scroll;
  padding-bottom: 20px;

  .course {
    flex-shrink: 0;
    display: inline-block;
    overflow: hidden;
    width: 260px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba($gray, 0.3);
    margin: 0 24px;

    &:first-child {
      margin-left: 0;
    }

    > img.cover {
      width: 100%;
    }

    main.content {
      padding: 12px 16px 20px;

      .title {
        > h4 {
          color: $text-orange;
          font-size: 12px;
          transform: scale(0.9) translateX(-5%);
          white-space: nowrap;
        }

        > h2 {
          font-size: 20px;
          margin: 8px 0;
        }
      }

      > p {
        color: $text-gray;
        line-height: 1.8;
        margin-bottom: 12px;
        //下面这四行作用，多行文本超出3行显示省略号
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }

      .more {
        color: $theme-color;

        span {
          padding-right: 4px;
        }
      }
    }

  }
}
</style>
