<template>
  <header class="appHeader">
    <img class="logoImage" src="@/assets/img/home_logo.png" alt="logo" @click="onBackHome">
    <div class="downloadButton" @click="onDownload">立即下载</div>
  </header>
</template>

<script>
export default {
  name: "MobileHeader",
  methods:{
    onBackHome(){
      this.$route.path !== "/home" && this.$router.push("/home")
    },
    onDownload(){
      window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.cn.cloudrefers.cloudrefersclassroom&fromcase=40003"
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.appHeader {
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 8px 12px 0 #F2F2F2;

  .logoImage {
    height: 44px;
  }

  .downloadButton {
    color: $theme-color;
    background: rgba($theme-color, 0.1);
    padding: 10px 16px;
    border-radius: 4px;
    &:active{
      opacity: 0.7;
    }
  }
}
</style>