<template>
  <section class="container">
    <h2 class="title">课程建设方案</h2>
    <div class="content">
      <p>
        实现教育现代化离不开教育信息化，信息化要服务于教育现代化。信息化课程建设以学习者为中心，构建教学资源和教学过程。根据教育部关于印发《教育信息化2.0行动计划》的通知要实现从专用资源向大资源转变；从提升学生信息技术应用能力、向提升信息技术素养转变；从应用融合发展，向创新融合发展转变。为促进新一代信息技术与教育教学深度融合，构建以学习者为中心的课程资源尤其重要，为扩大优质资源覆盖面，利用信息化、互联网、大数据等技术手段开发适合教师教学、学生学习的课程资源势在必行。以专业为基础进行数字化教学资源的建设和组织，并实现专业的资源共享、共用，实现专业软资产的不断积累。
      </p>
      <p>
        课程的构建基于企业真实流程，将企业需求融入人才培养环节，推行面向企业真实业务环境的任务培养模式。课程架构包括：教学设计，教学目标，教学内容，教学场景，实训练习等；形成了一套助教、助学、助训、助考、助评于一体的课堂教学模式。可帮助教师建好课堂、用好课堂、管好课堂；帮助学生在学习过程中接触企业真实工作任务及工作流程。可边学边练边巩固，使学生在校期间即实现就业与岗位零距离衔接，利用全新的学习方式提高学生的学习兴趣。
      </p>
      <img src="@/assets/img/Solutions/solution_courseMindMap.png/" alt="课程建设方案">
    </div>
  </section>
</template>

<script>
export default {
  name: "courseBuilding"
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.container {
  padding: 20px;
}

.title {
  margin-bottom: 16px;
}

.content {
  > img {
    margin-top: 16px;
    width: 100%;
  }

  > p {
    color: $text-gray;
    text-indent: 2em;
    line-height: 1.8;

    & + p {
      margin-top: 16px;
    }
  }
}
</style>