import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { isMobile } from '@/utils'
import MobileApp from '@/MobileApp.vue'
import mobileRouter from "./router/mobile"

import {
  Menu,
  MenuItem,
  Backtop,
  Button,
  Scrollbar,
  Icon,
  Dialog,
  Carousel,
  CarouselItem,
  Card,
  Row,
  Col,
  Container,
  Header,
  Aside,
  Main,
  Image
} from "element-ui";

//css样式reset
import './assets/Reset.scss'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/base.css';

Vue.use(Button);
Vue.use(Backtop);
Vue.use(Scrollbar);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Image)
Vue.config.productionTip = false

new Vue({
  router: isMobile() ? mobileRouter : router,
  store,
  render: h => h(isMobile() ? MobileApp : App)
}).$mount('#app')
