<template>
  <div>
    <nav class="navList">
      <router-link class="navItem" active-class="activeNav"
                   v-for="nav in navList" :key="nav.name"
                   :to="`/about/${nav.path}`" replace>
        {{ nav.name }}
      </router-link>
    </nav>

    <transition name="fade">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: "about",
  data(){
    return {
      navList: [
        {name: "公司简介", path: "introduction"},
        {name: "公司资质", path: "qualification"},
        {name: "公司证书", path: "patent"},
        {name: "合作案例", path: "customer"},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";
@import "~@/assets/Transition.scss";

.navList {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  overflow: scroll;
  background: rgba($theme-color, 0.02);
  padding: 16px 20px;
  justify-content: space-around;
  align-items: center;

  .navItem {
    flex-shrink: 0;
    color: $gray;

    &.activeNav {
      color: $theme-color
    }
  }
}
</style>