<template>
  <main class="wrapper">
    <img class="cover" :src="renderItem.img" :alt="renderItem.name + '封面'">
    <img class="icon" :src="renderItem.icon" :alt="renderItem.name + '图标'">
    <h2>{{ renderItem.name }}</h2>
    <article>{{ renderItem.description }}</article>
    <div class="feature">
      <div class="title">{{ renderItem.feature.title }}</div>
      <div class="content" v-if="typeof renderItem.feature.content === 'string'">
        {{ renderItem.feature.content }}
      </div>
      <div class="content" v-else>
        <span v-for="keyword in renderItem.feature.content" :key="keyword">{{ keyword }}</span>
        <span>...</span>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "digitalPlatformContent",
  computed: {
    renderItem(){
      return this.renderMap[this.$route.params.name]
    }
  },
  data(){
    return {
      renderMap: {
        industryDynamics: {
          img: require('@/assets/img/Platform-Icon/Dialog-Images/bg_行企动态.png'),
          icon: require('@/assets/img/Platform-Icon/行企动态.svg'),
          name: "行企动态",
          description: "平台把国家教育政策和行业动态及重大新闻事件融入各个专业的每门课程。教育政策和行企动态实时更新，让教师和学生在“教与学”的过程中，及时了解相关政策与行业动态，以便更好地辅助教师教学及学生学习。",
          feature: {
            title: "特点",
            content: "在“教与学”的过程中，师生能及时了解相关政策、行业动态及企业用人需求。",
          }
        },
        courseBuilding: {
          img: require('@/assets/img/Platform-Icon/Dialog-Images/bg_课程建设.png'),
          icon: require('@/assets/img/Platform-Icon/课堂建设.svg'),
          name: "课程建设",
          description: "平台为课程共建共享提供技术支撑，支持各类教 学资源建设(文本资源、音视频资源、考核练习资源等)、课堂实训资源建设(在线模拟实训、交互式实训等)及其他课程资源建设(教学拓展资源、课堂活动资源等)，上传平台的所有资源均可进行区块链存证，保护作者创作版权。",
          feature: {
            title: '功能',
            content: ['可建文本资源', '媒体资源', 'H5交互式资源', '考核资源'],
          }
        },
        teachingPreparation: {
          icon: require('@/assets/img/Platform-Icon/教学备课.svg'),
          img: require('@/assets/img/Platform-Icon/Dialog-Images/bg_教学备课.png'),
          name: '教学备课',
          description: '平台备课分为：备教学内容和备管教方法。\n(1) 备教学内容教师可依据班级学情及具体学生学情，进行个性化备课，因材施教。教师可在平台现存课程的基础上，对课程资源及实训内容进行在线编辑，灵活调整知识结构，以便于课堂教学。同时，也可添加新的教学资源，丰富教学内容。\n(2) 备管教方法备课堂教学活动和教学方法。课堂以学生为中心，根据过往学生在学习中提出的问题和教学活动与教学组织，提前给予教师反馈，提升课堂教学效果。',
          feature: {
            title: '功能',
            content: '标准化的课程内容，可添加个性化的资源，可对全部教学场景进行教学设计。',
          }
        },
        teaching: {
          icon: require('@/assets/img/Platform-Icon/课堂教学.svg'),
          img: require('@/assets/img/Platform-Icon/Dialog-Images/bg_课堂教学.png'),
          name: '课堂教学',
          description: '根据备课内容组织并实施教学，所有教学行为都可采集数据，为及时了解并掌握课堂教学质量和效果提供有力支撑。课堂教学可实现随教随练，丰富的课堂活动设置可推动学生深度参与课堂教学，形成教学共同体。',
          feature: {
            title: '特点',
            content: ['知识点讲解', '发布教学活动', '发布课程实训', '开展课堂互动'],
          }
        },
        learning: {
          icon: require('@/assets/img/Platform-Icon/学生学习.svg'),
          img: require('@/assets/img/Platform-Icon/Dialog-Images/bg_学生学习.png'),
          name: '学生学习',
          description: '平台建有完整的课程结构，丰富的课程资源、实训训练、拓展资源等学习资料。学生可通过任意终端进行自主学习;同时还可根据教师课堂教学要求进行学习，所有学习轨迹都可进行数据采集，生成学习周报，及时掌握学习成绩和学习状态。',
          feature: {
            title: '功能',
            content: ['参与教学活动', '线上线下混合式学习', '参与课堂实训', '对教学进行评价'],
          }
        },
        teachingInteraction: {
          icon: require('@/assets/img/Platform-Icon/教学互动.svg'),
          img: require('@/assets/img/Platform-Icon/Dialog-Images/bg_教学互动.png'),
          name: '教学互动',
          description: '平台提供各种教学场景互动工具，教师可在课前、课中、课后通过任意终端与学生进行跨时空交流互动，提高学生的参与感。教师可在课前、课中、课后发布所需的学习任务、学习活动及答疑解惑，互动过程中产生的数据均可作为过程考核的一部分。',
          feature: {
            title: '功能',
            content: '调查问卷、通知、线上线下答题、讨论、选人、点名 ……',
          }
        },
        training: {
          icon: require('@/assets/img/Platform-Icon/课堂实训.svg'),
          img: require('@/assets/img/Platform-Icon/Dialog-Images/bg_课堂实训.png'),
          name: '课堂实训',
          description: '平台提供实训底层技术，帮助教师在讲解专业基础知识的同时渗透对应的实训技能，达到一体化教学的目的，提升教学效果;帮助学生边学边练，提高学习效率。',
          feature: {
            title: '功能',
            content: '有教有练，在一体化教学实践中驱动并完成教学任务。',
          }
        },
        schoolworkTest: {
          icon: require('@/assets/img/Platform-Icon/作业试卷.svg'),
          img: require('@/assets/img/Platform-Icon/Dialog-Images/bg_作业试卷.png'),
          name: '作业试卷',
          description: '教师可以利用平台已有题库，或者一键导入自己的题库，组卷、发布作业或考试，平台自动批改。每道题与知识点关联，可依据知识点、题型、难易度、使用次数和错误率组卷出题。平台还可以智能推送与错题相似的题型和知识点，以巩固学习成效。',
          feature: {
            title: '特点',
            content: ['发布作业', '发布考核', '试题组卷'],
          }
        },
        assessment: {
          icon: require('@/assets/img/Platform-Icon/考核评价.svg'),
          img: require('@/assets/img/Platform-Icon/Dialog-Images/bg_考核评价.png'),
          name: '考核评价',
          description: '平台存储所有“教与学”的全部过程和考核结果，为教学评价提供科学依据。教师对学生学习过程进行考核，学生对教师的教学内容和教学方法、态度进行评价;平台记录“教与学”的全部过程和行为轨迹，通过大数据分析 出具教学报告和学生学习报告;同时进行区块链存证，所有教学过程和考核结果可溯源，确保其真实性，实现“教与学”的过程性评价与结果性评价等多元评价体系。',
          feature: {
            title: '功能',
            content: ['课堂考核', '课堂活动考核', '线上考核', '实训考核'],
          }
        },
        data: {
          icon: require('@/assets/img/Platform-Icon/数据中心.svg'),
          img: require('@/assets/img/Platform-Icon/Dialog-Images/bg_数据中心.png'),
          name: '数据中心',
          description: '平台对“教与学”的全场景、全过程数据进行采集，捕捉操作痕迹，形成教学大数据并及时分析，形成教学预警反馈机制，对于保障课堂教学质量、改进教学管理有显著促进作用。',
          feature: {
            title: '功能',
            content: '全场景数据采集，捕捉操作过程，形成结果性数据报告和过程性数据反馈，对教师和学生进行人物画像。',
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";
@import "~@/assets/Transition.scss";

.wrapper {
  padding: 20px;

  > img.cover {
    width: 100%;
    margin-bottom: 24px;
  }

  > img.icon {
    height: 32px;
    margin-bottom: 16px;
  }

  > h2 {
    margin-bottom: 12px;
  }

  > article {
    line-height: 1.6;
    white-space: pre-wrap;
  }

  .feature {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      color: $theme-color;
      white-space: nowrap;
      margin-left: 14px;
      margin-right: 20px;
      position: relative;
      line-height: 1.5;

      &:before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -14px;
        content: "";
        width: 0.6em;
        height: 0.6em;
        background: $theme-color;
      }
    }

    .content {
      flex-grow: 1;
      line-height: 1.5;
      display: flex;
      flex-wrap: wrap;

      > span {
        flex-shrink: 0;
        width: 50%;
        max-width: 160px;
      }
    }
  }
}
</style>