<template>
  <div class="company">
    <main>
      <div id="imgWrap">
        <img src="../../assets/img/home_computer.png" alt="公司简介图片">
      </div>
      <section>
        <Title titleName="公司介绍"></Title>
        <p style="text-align: justify">广州然也教育科技有限公司，是一家从事智慧教育的国家高新技术企业。公司坚持把信息技术与教育教学实践深度融合，在融合型数字化课程研发、融合型数字化教学平台搭建以及数字出版等领域不断深耕，锐意进取，始终恪守“让科技赋能教育，让教育内容服务用户”的宗旨，利用数字技术，致力构建“教学练考评管”一体化的闭环教育生态系统。</p>
        <p style="text-align: justify">基于前端数字化课程在教学场景中的应用，移步后端课程研发，融合数字技术与数据应用，构建各专业课程的教学体系模型，建设理实一体、蕴含教学全要素、覆盖教学全过程的…</p>
        <router-link target="_blank" class="more" to="/about">
          了解更多
          <img src="../../assets/img/home_arrow.svg" alt="home_arrow">
        </router-link>
      </section>
    </main>
    <div class="customer">
      <h2>多个客户的信赖之选</h2>
      <el-carousel height="200px"
                   :interval="5000"
                   trigger="click"
                   indicator-position="none"
                   arrow="never"
                   @change="rearrange">
        <el-carousel-item class="imageWrapper" v-for="(school,i) in activeSchoolList" :key="i">
          <img v-for="(item,index) in school" :key="index" :src="require('../../assets/img/CarouselSchool/' + item)" :alt="item">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import Title from '../Title.vue'

export default {
  name: "Company",
  components: {
    Title
  },
  data() {
    return {
      schoolRootList: [
        'home_one.png','home_two.png','home_three.png','home_four.png',
        'home_five.png', 'home_six.png','home_seven.png','home_eight.png',
        'home_nine.png', 'home_ten.png', 'home_eleven.png','home_twelve.png',
        'home_thirteen.png', 'home_fourteen.png', 'home_fifteen.png', 'home_sixteen.png',
        'home_seventeen.png', 'home_eighteen.png','home_nineteen.png'
      ],
      activeSchoolList: [],
      done: false
    }
  },
  methods: {
    shuffle(arr) {
      let i = arr.length;
      while (i) {
        let j = Math.floor(Math.random() * i--);
        [arr[j], arr[i]] = [arr[i], arr[j]];
      }
    },
    renderSchoolList() {
      // this.shuffle(this.schoolRootList)
      // this.schoolRootList.slice(0, 17)
      this.activeSchoolList = []
      for (let i = 0; i < 3; i++) {
        this.activeSchoolList.push(this.schoolRootList.slice(8 * i, 8 * (i + 1)))
      }
      // console.log(this.activeSchoolList)
    },
    rearrange(i, j) {
      if (j === 2) {
        // 当轮播图切换到最后一组，重新触发renderSchoolList函数
        this.renderSchoolList()
      }
    }
  },
  mounted() {
    // 初始化一下
    this.renderSchoolList()
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/Variable.scss";

.company {
  padding: 15vw 0 0 0;
  background: rgba($theme-color, 0.02);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // height: 45vw;
}

main {
  display: flex;
  justify-content: space-between;
  width: 90vw;

  #imgWrap {
    img {
      width: 42vw;
      padding-top: 60px;
      //  height: 40vw;
    }
  }

  section {
    width: 50%;
    text-align: left;

    ::v-deep .title {
      justify-content: left;
    }

    p {
      font-size: 1.8vw;
      text-indent: 2em;
      line-height: 1.6em;
      font-family: "Microsoft YaHei UI", serif;
      color: #5B5B5B;
      margin-bottom: 2vw;
    }

    .more {
      font-family: Microsoft Yahei UI, serif;
      color: $theme-color;

      img {
        padding-left: 1vw;
        width: 1.5vw;
      }
    }
  }
}

.customer {
  padding-top: 4vw;
  text-align: center;

  h2 {
    font-family: MicrosoftYaHeiSemibold, 'Microsoft YaHei UI', serif;
    padding-bottom: 10px;
  }

  .el-carousel {
    margin-top: 1vw;
    width: 90vw;

    img {
      width: 22vw;
    }
  }
}

.imageWrapper{
  img{
    transform: scale(90%);
    margin-bottom: 1%;
  }
}

@media (min-width: 1229px) {
  .company {
    height: 700px;
    padding: 70px 0 50px 0;
  }
  main {
    width: 1050px;

    #imgWrap {
      img {
        width: 500px;
        padding-top: 200px;
        //height: 100%;
      }
    }

    section {
      width: 46%;
      padding-top: 200px;

      p {
        font-size: 18px;
        margin-bottom: 1.6em;
      }

      .more {
        font-size: 18px;

        img {
          padding-left: 14px;
          width: 16px;
        }
      }
    }
  }
  .customer {
    padding: 50px 0 130px;

    h2 {
      padding-top: 10px;
      font-size: 30px;
    }

    .el-carousel {
      margin-top: 20px;
      width: 1080px;

      img {
        width: 270px;
      }
    }
  }
}
</style>
