<template>
  <section class="product">
    <MobileSectionTitle>产品服务</MobileSectionTitle>
    <div class="buttonList">
      <div v-for="item in navItems" :key="item.name"
           @click="currentComponent = item.component"
           class="navItem"
           :class="{selected: currentComponent === item.component}">
        {{ item.name }}
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <main :is="currentComponent"/>
    </transition>
  </section>
</template>

<script>
import 'element-ui/lib/theme-chalk/base.css';
import MobileSectionTitle from '@/components/mobile/public/MobileSectionTitle.vue'
import DigitalClass from "@/components/mobile/home/productDigitalClass.vue"
import DigitalPlatform from "@/components/mobile/home/productDigitalPlatform.vue"
import QualityPlatform from "@/components/mobile/home/productQualityPlatform.vue"
import TechnicalServices from "@/components/mobile/home/productTechnicalServices.vue"


export default {
  name: "product",
  components: {
    MobileSectionTitle, DigitalClass, DigitalPlatform,
    QualityPlatform, TechnicalServices
  },
  data(){
    return {
      currentComponent: 'digitalClass',
      navItems: [
        {name: '云指课堂数字化课程', component: 'digitalClass'},
        {name: '融合型数字化教学平台', component: 'digitalPlatform'},
        {name: '教学质量管理平台', component: 'qualityPlatform'},
        {name: '技术服务支撑', component: 'technicalServices'},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.product {
  margin-top: 20px;
  padding: 12px 16px;

  .buttonList {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 16px 0;
    margin: 10px auto 0;

    .navItem {
      text-align: center;
      border-radius: 100px;
      padding: 8px;
      width: 42%;
      margin: 0 4px 12px;
      font-size: 13px;
      border: 1px solid rgba($gray, 0.3);
      font-weight: bold;
    }

    .navItem.selected {
      background: rgba($theme-color, 0.06);
      color: $theme-color;
      border: 1px solid rgba($theme-color, 0.06);
    }
  }
}
</style>