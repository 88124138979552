<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>


<script>
  // @ is an alias to /src
  export default {
  name: 'App',
}
</script>

<style lang="scss">
#app {
  box-sizing: border-box;
  font-family: Microsoft Yahei UI ,Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
