<template>
  <div>
    <el-container style="height: 100vh;">
      <el-header style="height: 13vh;">
        <img src="@/assets/img/Solutions/solution_header.png/" alt="解决方案页头">
      </el-header>
      <el-container class="inside-container">
        <el-aside width="200px">
          <el-menu
              :default-active="selectedIndex"
              @select="jumpTo"
              text-color="#B5A3A5"
              active-text-color="#C30D23"
          >
            <el-menu-item index="0">
              <img :class="{visible:selectedIndex ==='0'}" src="@/assets/img/About/company_tag.svg/" alt="company_tag"
                   @load="jumpTo(selectedIndex)">
              <span slot="title">平台建设方案</span>
            </el-menu-item>
            <el-menu-item index="1">
              <img :class="{visible:selectedIndex ==='1'}" src="@/assets/img/About/company_tag.svg/" alt="company_tag"
                   @load="jumpTo(selectedIndex)">
              <span slot="title">课程建设方案</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main style="height:83vh;">
          <el-scrollbar style="height:100%;">
            <main class="content">
              <div class="platform">
                <h3 id="0">平台建设方案</h3>
                <p>
                  实现教育现代化离不开教育信息化，信息化要服务于教育现代化。教学信息化平台建设以学习者为中心，构建“教”与“学”过程。同时构建能够满足教学资源建设长期持续发展的应用框架，实现平台支撑的集中化。教师应用平台可建设课程资源，开展教学管理，备课授课、指导学生学习、批改作业及交流答疑等教学活动。线上线下相结合的混合教学模式，建成时时可教、处处能教的教学场景。学生可根据教师课程按排有针对性在平台上学习。可利用平台的资源和课程进行课前预习、作业、自测、拓展阅读、实训练习和错题练习等学习活动。养成自主管理、自主学习的好习惯，建成处处能学，时时可学的学习环境。平台提供云端支持，动态更新，可多平台多终端互动，为学习者提供了一个跨时间跨空间跨地域的学习交流平台。达到人人皆学、处处能学、时时可学的学习场景。同时响应《教育部关于加强高等学校在线开放课程建设应用与管理的意见》（教高[2015]3号）精神，为进一步推动我国在线开放课程建设与应用共享，对专业教学资源结构进行开放式重组，建立开放式管理网络运行平台，实现数字化学习资源的标准、规范和方法。</p>
                <img src="@/assets/img/Solutions/solution_platformMindMap.png/" alt="平台建设方案">
              </div>
              <div class="course">
                <div id="1">
                  <h3>课程建设方案</h3>
                  <p>
                    实现教育现代化离不开教育信息化，信息化要服务于教育现代化。信息化课程建设以学习者为中心，构建教学资源和教学过程。根据教育部关于印发《教育信息化2.0行动计划》的通知要实现从专用资源向大资源转变；从提升学生信息技术应用能力、向提升信息技术素养转变；从应用融合发展，向创新融合发展转变。为促进新一代信息技术与教育教学深度融合，构建以学习者为中心的课程资源尤其重要，为扩大优质资源覆盖面，利用信息化、互联网、大数据等技术手段开发适合教师教学、学生学习的课程资源势在必行。以专业为基础进行数字化教学资源的建设和组织，并实现专业的资源共享、共用，实现专业软资产的不断积累。 </p>
                  <p>课程的构建基于企业真实流程，将企业需求融入人才培养环节，推行面向企业真实业务环境的任务培养模式。
                    课程架构包括：教学设计，教学目标，教学内容，教学场景，实训练习等；形成了一套助教、助学、助训、助考、助评于一体的课堂教学模式。可帮助教师建好课堂、用好课堂、管好课堂；帮助学生在学习过程中接触企业真实工作任务及工作流程。可边学边练边巩固，使学生在校期间即实现就业与岗位零距离衔接，利用全新的学习方式提高学生的学习兴趣。</p>
                </div>
                <img src="@/assets/img/Solutions/solution_courseMindMap.png/" alt="课程建设方案">
              </div>
            </main>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "Solution",
  data() {
    return {
      selectedIndex: '0',
    }
  },
  methods: {
    jumpTo(index) {
      let selectedElement = document.getElementById(index)
      this.selectedIndex = index
      selectedElement.scrollIntoView({behavior: "smooth"})
    }
  },
  mounted() {
    this.selectedIndex = this.$route.params.index
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/Variable.scss";
// 滚动条样式
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

// 页头样式
.el-header {
  background: linear-gradient(140deg, #C30D23 0%, #E31E49 100%);
  color: white;
  padding: 0;
  overflow: hidden;

  img {
    padding-left: 10vw;
    margin: 1vw auto 0 auto;
    width: 90vw;
    min-width: 1000px;
  }

}

// 侧边导航栏样式
.el-aside {
  margin-top: 4vh;
  text-align: center;
  line-height: 200px;
}

.el-menu-item:focus, .el-menu-item:hover {
  background: none;
}

.is-active {
  border-right: 2px solid $theme-color;
}

.el-menu-item {
  border-bottom: 1px solid #ebebeb;

  .visible {
    opacity: 1;
  }

  img {
    opacity: 0;
  }

  span {
    padding-left: 2vw;
  }
}

.el-menu-item:nth-last-child(1) {
  border-bottom: none;
}


// 主体内容样式
@mixin content-title {
  color: #373636;
  font-size: 2.1vw;
  padding-bottom: 1em;
}

@mixin content-paragraph {
  font-size: 1.3vw;
  font-family: Microsoft Yahei UI, serif;
  font-weight: 400;
  color: #5B5B5B;
  text-indent: 2em;
  line-height: 1.6em;
}

.el-main {
  // header的白条消失，需要在.introduction里加padding-top: 4vh;
  //padding: 0 5px 20px 50px;
  padding: 4vh 5px 20px 50px;
  color: #373636;
}

.content {
  width: 80%;
}

.content > .platform {
  // header的白条消失，需要在.el-main里写成 padding: 4vh 5px 20px 50px;
  //padding-top: 4vh;
  padding-bottom: 5vw;

  h3 {
    @include content-title;
  }

  img {
    width: 100%;
  }

  p {
    @include content-paragraph;
    padding-bottom: 1.8em;
  }
}

.content > .course {
  padding-bottom: 5vw;

  h3 {
    @include content-title
  }

  p {
    @include content-paragraph;
    padding-bottom: 2em;
  }

  img {
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .el-header {
    //background: linear-gradient(135deg, #2240D3 0%, #4B7AF9 100%);
    //color: white;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;

    img {
      padding: 20px 200px 0 0;
      width: 1000px;
    }
  }
  .el-menu-item > span {
    padding-left: 24px;
  }
  .el-aside {
    margin-left: 100px;
  }
  // 主体内容样式
  @mixin content-title-adaptive {
    color: #373636;
    font-size: 25px;
    padding-bottom: 1em;
  }
  @mixin content-paragraph-adaptive {
    font-size: 16px;
    font-family: Microsoft Yahei UI, serif;
    font-weight: 400;
    color: #5B5B5B;
    text-indent: 2em;
    line-height: 1.6em;
  }
  .content {
    margin-left: 40px;
    width: 850px;
  }
  .content > .platform {
    padding-bottom: 60px;

    h3 {
      @include content-title-adaptive;
    }

    p {
      @include content-paragraph-adaptive;
      padding-bottom: 1.8em;
    }
  }
  .content > .course {
    h3 {
      @include content-title-adaptive
    }

    p {
      @include content-paragraph-adaptive;
    }
  }
}

@media (min-width: 1600px) {
  .el-aside {
    margin-left: 200px;
  }
}

@media (min-width: 1800px) {
  .el-aside {
    margin-left: 300px;
  }
}

@media (min-width: 2000px) {
  .el-aside {
    margin-left: 400px;
  }
}
</style>
