<template>
  <div class="download">
    <Title title-name="软件下载"></Title>
    <img class="downloadBackground" src="../../assets/img/home_down.png" alt="downloadBackground">
    <div class="content">
      <h3>云指课堂手机端</h3>
      <h3>APP下载</h3>
      <div class="buttons">
        <div class="downloadButton">
          <img src="../../assets/img/home_downloadBarcode.png" alt="下载二维码" class="downloadBarcode">
          <img src="../../assets/img/home_ios.svg" alt="home_ios" class="icon">
          <div class="text">
            <div>App Store</div>
            <div>下载</div>
          </div>
        </div>
        <div class="downloadButton">
          <img src="../../assets/img/home_downloadBarcode.png" alt="下载二维码" class="downloadBarcode">
          <img src="../../assets/img/home_android.svg" alt="home_android" class="icon">
          <div class="text">
            <div>Android</div>
            <div>下载</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Title from "../Title";

export default {
  name: "Download",
  components: {Title}
}
</script>

<style lang="scss" scoped>
.download {
  padding: 6vw 0;
  background: white;
  text-align: center;
  position: relative;

  .downloadBackground {
    width: 85%;
    //transform: scale(50%);
  }
}

.content {
  position: absolute;
  top: 34%;
  left: 17%;
  text-align: left;

  h3 {
    color: white;
    font-size: 4vw;
    line-height: 1.6em;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  .downloadButton {
    margin-top: 2.5vw;
    margin-right: 1.5vw;
    display: flex;
    width: 12vw;
    height: 5vw;
    background: #FFFFFF;
    border-radius: 0.5vw;
    align-items: center;
    justify-content: left;
    position: relative;

    .icon {
      width: 1.5vw;
      padding: 0 1vw 0 1.5vw;
    }

    .downloadBarcode {
      width: 100%;
      position: absolute;
      top: -270%;
      left: 0;
      transition: all 200ms;
      opacity: 0;
    }

    .text {
      display: flex;
      flex-direction: column;
      padding: 0.3vw 0;
      line-height: 1.4em;
      color: #373636;
      font-size: 1.3vw;
    }
  }

  .downloadButton:hover {
    .downloadBarcode {
      transition: all 200ms;
      opacity: 1;
    }
  }
}

@media (min-width: 1229px) {
  .download {
    padding: 72px 0;
    width: 1300px;
    margin: 0 auto;
  }
  .content > h3 {
    font-size: 48px;
  }
  .buttons {
    .downloadButton {
      margin-top: 30px;
      margin-right: 25px;
      width: 143px;
      height: 62px;
      border-radius: 6px;

      .icon {
        width: 20px;
        padding: 0 12px 0 18px;
      }

      .downloadBarcode {
        top: -250%;
      }

      .text {
        font-size: 15px;
      }
    }
  }


}
</style>
