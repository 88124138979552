<template>
  <section class="container">
    <h2 class="title">公司证书</h2>
    <div class="patentWrapper">
      <img v-for="(patent,index) in patentList" :key="index" :src="patent" :alt="`证书-${index}`">
    </div>

  </section>
</template>

<script>
export default {
  name: "patent",
  data(){
    return {
      patentList: [
        require('@/assets/img/About/官网-证书-实训.png'),
        require('@/assets/img/About/官网-证书-投屏.png'),
        require('@/assets/img/About/官网-证书-出版.png'),
        require('@/assets/img/About/官网-证书-资源库建设平台.png'),
        require('@/assets/img/About/官网-证书-然也信息化教学平台.png'),
        require('@/assets/img/About/官网-证书-资源建设系统.png'),
        require('@/assets/img/About/官网-证书-云指课堂教学资源系统.png'),
        require('@/assets/img/About/官网-证书-信息化平台ios.png'),
        // require("@/assets/img/About/官网-证书-国贸安卓APP.png")
      ],
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.container {
  padding: 20px;
}

.title {
  margin-bottom: 16px;
}

.patentWrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  > img {
    width: 45%;
    margin: 6px 0;
  }
}
</style>