<template>
  <section class="container">
    <h2 class="title">平台建设方案</h2>
    <div class="content">
      <p>
        实现教育现代化离不开教育信息化，信息化要服务于教育现代化。教学信息化平台建设以学习者为中心，构建“教”与“学”过程。同时构建能够满足教学资源建设长期持续发展的应用框架，实现平台支撑的集中化。教师应用平台可建设课程资源，开展教学管理，备课授课、指导学生学习、批改作业及交流答疑等教学活动。线上线下相结合的混合教学模式，建成时时可教、处处能教的教学场景。学生可根据教师课程按排有针对性在平台上学习。可利用平台的资源和课程进行课前预习、作业、自测、拓展阅读、实训练习和错题练习等学习活动。养成自主管理、自主学习的好习惯，建成处处能学，时时可学的学习环境。平台提供云端支持，动态更新，可多平台多终端互动，为学习者提供了一个跨时间跨空间跨地域的学习交流平台。达到人人皆学、处处能学、时时可学的学习场景。同时响应《教育部关于加强高等学校在线开放课程建设应用与管理的意见》（教高[2015]3号）精神，为进一步推动我国在线开放课程建设与应用共享，对专业教学资源结构进行开放式重组，建立开放式管理网络运行平台，实现数字化学习资源的标准、规范和方法。
      </p>
      <img src="@/assets/img/Solutions/solution_platformMindMap.png/" alt="平台建设方案">
    </div>
  </section>
</template>

<script>
export default {
  name: "platformBuilding"
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.container {
  padding: 20px;
}

.title {
  margin-bottom: 16px;
}

.content {
  > img {
    margin-top: 16px;
    width: 100%;
  }

  > p {
    color: $text-gray;
    text-indent: 2em;
    line-height: 1.8;

    & + p {
      margin-top: 16px;
    }
  }
}
</style>