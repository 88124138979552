<template>
  <div class="cardsWrapper">
    <div class="card" v-for="item in itemList" :key="item.title">
      <img :src="item.imageUrl" :alt="item.title"/>
      <div class="title">
        <img src="@/assets/img/QualityPlatform/qualityPlatform_tag.png" alt="图标"/>
        <h4>{{ item.title }}</h4>
      </div>
      <p class="description">{{ item.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "qualityPlatform",
  data(){
    return {
      itemList: [
        {
          imageUrl: require('@/assets/img/QualityPlatform/qualityPlatform_数据全面丰富.png'),
          title: '数据全面丰富',
          description: '教学数据从教学设计、教学内容、备课授课、学习互动、实训练习、行为轨迹进行全面采集与挖掘，呈现教学过程与教学全场景。'
        },
        {
          imageUrl: require('@/assets/img/QualityPlatform/qualityPlatform_数据来源真实.png'),
          title: '数据来源真实',
          description: '教师备课、授课行为过程数据，学生评价数据及学生学习过程和考核结果数据，全部区块链存证，不可篡改，确保数据真实性。'
        },
        {
          imageUrl: require('@/assets/img/QualityPlatform/qualityPlatform_数据反馈过程.png'),
          title: '数据反馈过程',
          description: '数据采集丰富全面，可反馈“教与学”的全部场景及过程，可管控教师课堂教学状况和学生学习状况。'
        },
        {
          imageUrl: require('@/assets/img/QualityPlatform/qualityPlatform_数据时效性强.png'),
          title: '数据时效性强',
          description: '通过对教学过程数据和学习过程数据的把控，对偏离教学和学习的情况及时预警。'
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.cardsWrapper {
  padding: 0 20px;
}

.card {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  > img {
    width: 100%;
  }

  .title {
    padding: 8px 0;
    display: flex;
    align-items: center;

    > img {
      height: 1em;
      padding-right: 8px;
    }
  }

  .description {
    color: $text-gray;
    font-size: 13px;
    line-height: 1.8;
  }
}
</style>