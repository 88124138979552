<template>
  <div class="wrapper">
    <img src="../../assets/img/Courses/BasicAccounting_1.jpg" alt="BasicAccounting_1">
    <img src="../../assets/img/Courses/BasicAccounting_2.jpg" alt="BasicAccounting_2">
  </div>
</template>

<script>
export default {
  name: "BasicAccounting"
}
</script>

<style scoped lang="scss">
.wrapper{
  img{
    width: 100%;
  }
}
</style>
