<template>
  <div class="wrapper">
    <img src="../../assets/img/Courses/InternationalFreightForwarderPractice_1.jpg" alt="InternationalFreightForwarderPractice_1">
    <img src="../../assets/img/Courses/InternationalFreightForwarderPractice_2.jpg" alt="InternationalFreightForwarderPractice_2">
  </div>
</template>

<script>
export default {
  name: "InternationalFreightForwarderPractice"
}
</script>

<style scoped lang="scss">
.wrapper{
  img{
    width: 100%;
  }
}
</style>
