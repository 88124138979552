<template>
  <div class="portal">
    <div class="left-wrap">
      <img src="../../assets/img/home_slogan.svg" alt="home_slogan">
      <div class="buttons">
        <el-button class="login" size="medium" @click="openNewPage('https://web.ranyetech.com/login.html')">
          登录
          <span class="icon">
          <i class="el-icon-right"></i>
        </span>
        </el-button>
        <el-button class="tourist" size="medium" @click="openNewPage('https://web.ranyetech.com/index.html')">游客查看
        </el-button>
      </div>

    </div>
    <div class="right-wrap">
      <img src="../../assets/img/home_banner.png" alt="home_banner">
    </div>
  </div>
</template>

<script>
export default {
  name: "Portal",
  methods: {
    openNewPage(url) {
      window.open('_blank').location = url;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/Variable.scss";

.portal {
  display: flex;
  justify-content: space-between;
  min-width: 735px;
}

.left-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;

  img {
    padding-bottom: 7vw;
    width: 30vw;
  }
}

.buttons {
  button {
    min-width: 10vw;
    min-height: 3vw;
    border-radius: .5vw;
    font-size: 1vw;
  }

  .login {
    background: $theme-color;
    color: white;
    position: relative;
    text-align: left;
    padding-left: 1vw;
    font-size: 1vw;
    margin-right: 1.2vw;
    transition: box-shadow 0.5s;

    &:hover {
      box-shadow: 0 3px 10px rgba($theme-color, .35);
      transition: box-shadow 0.3s;
    }

    .icon {
      position: absolute;
      right: 10%;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(#fff, 0.15);
      border-radius: 5px;
      padding: .5vw;
    }
  }

  .tourist {
    font-size: 1vw;
    &:hover,&:active,&:focus{
      background: rgba($theme-color, 0.08);
      color:  $theme-color;
      border-color: rgba($theme-color, 0.2);
    }
  }
}


.right-wrap {
  img {
    width: 50vw;
  }
}
</style>
