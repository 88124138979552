<template>
  <section class="container">
    <h2 class="title">公司简介</h2>
    <el-carousel :interval="5000" arrow="never" height="190px">
      <el-carousel-item class="imageItem" v-for="item in 4" :key="item">
        <img :src="require(`@/assets/img/About/carousel/图${item}.png`)" alt="公司图片">
      </el-carousel-item>
    </el-carousel>
    <div class="content">
      <p>
        广州然也教育科技有限公司，是一家从事智慧教育的国家高新技术企业。公司坚持把信息技术与教育教学实践深度融合，在融合型数字化课程研发、融合型数字化教学平台搭建以及数字出版等领域不断深耕，锐意进取，始终恪守“让科技赋能教育，让教育内容服务用户”的宗旨，利用数字技术，致力构建“教学练考评管”一体化的闭环教育生态系统。
      </p>
      <p>
        基于前端数字化课程在教学场景中的应用，移步后端课程研发，融合数字技术与数据应用，构建各专业课程的教学体系模型，建设理实一体、蕴含教学全要素、覆盖教学全过程的教学场景模型;构建“教与学”的闭环教育生态系统，形成教学共同体，进而推动教育数字化升级，服务教育教学全场景，从根本上提高教育教学质量，公司倾力推出融合型数字化课程、融合型数字化教学平台及教学质量管理平台。
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "introduction"
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.container {
  padding: 20px;
}

.title {
  margin-bottom: 16px;
}

.imageItem > img {
  width: 100%;
}

.content {
  padding-top: 16px;

  > p {
    color: $text-gray;
    text-indent: 2em;
    line-height: 1.5;

    & + p {
      margin-top: 16px;
    }
  }
}
</style>