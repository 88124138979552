import { render, staticRenderFns } from "./productTechnicalServices.vue?vue&type=template&id=7ae145b0&scoped=true&"
import script from "./productTechnicalServices.vue?vue&type=script&lang=js&"
export * from "./productTechnicalServices.vue?vue&type=script&lang=js&"
import style0 from "./productTechnicalServices.vue?vue&type=style&index=0&id=7ae145b0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ae145b0",
  null
  
)

export default component.exports