import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/mobile/Home.vue'
import About from '@/views/mobile/About.vue'
import AboutIntroduction from "@/views/mobile/About/Introduction.vue"
import AboutQualification from "@/views/mobile/About/Qualification.vue"
import AboutPatent from "@/views/mobile/About/Patent.vue"
import AboutCustomer from "@/views/mobile/About/Customer.vue"
import CourseDetail from '@/views/mobile/CourseDetail.vue'
import DigitalPlatform from '@/views/mobile/DigitalPlatform.vue'
import Solutions from '@/views/mobile/Solutions.vue'
import SolutionsPlatform from "@/views/mobile/Solutions/PlatformBuilding.vue"
import SolutionsCourse from "@/views/mobile/Solutions/CourseBuilding.vue"


Vue.use(VueRouter)

const mobileRoutes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/about',
    component: About,
    children: [
      {path: "introduction", component: AboutIntroduction},
      {path: "qualification", component: AboutQualification},
      {path: "patent", component: AboutPatent},
      {path: "customer", component: AboutCustomer}
    ]
  },
  {
    path: "/course/:name",
    component: CourseDetail
  },
  {
    path: "/digitalPlatform/:name",
    component: DigitalPlatform
  },
  {
    path: '/solutions',
    component: Solutions,
    children: [
      {path: "platformBuilding", component: SolutionsPlatform},
      {path: "courseBuilding", component: SolutionsCourse},
    ]
  },
]

const mobileRouter = new VueRouter({
  routes: mobileRoutes
})

mobileRouter.afterEach(() => {
  setTimeout(() => {
    mobileRouter.app.$el.querySelector('.appContainer').scrollTop = 0
  }, 0)
})

export default mobileRouter