<template>
<div class="technicalServices">
  <ul class="servicesList">
    <li v-for="(item,index) in serviceListData" :key="index">
      <div class="imageWrap">
        <img :src='item.imageLink' alt="servicesImage">
      </div>
      <h3>{{ item.title }}</h3>
      <p> {{ item.description }}</p>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  name: "TechnicalServices",
  data(){
    return{
      serviceListData:[
          {
            imageLink:require('../../../assets/img/p&s-plat1.jpg'),
            title:'在线开放课程建设',
            description:'利用网络信息技术推进教学改革，促进优质教学资源共享，结合教师与从业者丰富的经验完成数字化课程资源的制作与在线实训的开发。'
          },
        {
          imageLink:require('../../../assets/img/p&s-plat2.jpg'),
          title:'精品课程建设',
          description:'定制课程目标，深化教学内容与体系的研究方案，聚集更加优秀的教育资源，从而提高课程教学质量。'
        },
        {
          imageLink:require('../../../assets/img/p&s-plat3.jpg'),
          title:'微课建设',
          description:'依据教育工作者与学习者的特征组织和编排课程内容，采用多种手段制作课程资源，突破传统知识型的静态资源。'
        },
        {
          imageLink:require('../../../assets/img/p&s-plat4.jpg'),
          title:'金课建设',
          description:'与多所高校合作建设具有高阶性、创新性、挑战度的线上、线下、线上线下混合式、虚拟仿真和社会实践各类型课程。'
        },
      ]
    }

  }
}
</script>

<style scoped lang="scss">
.servicesList{
  width: 95vw;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  li{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23%;
    .imageWrap{
      width: 21vw;
      height: 21vw;
      overflow: hidden;
      border-radius: 1vw;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        height: 100%;
      }
    }
    h3{
      padding: 2.2vw 0 1.8vw 0;
      font-size: 2vw;
    }
    p{
      padding: 0 2%;
      font-size: 1.1vw;
      font-family:  "Microsoft YaHei UI",serif;
      color: #5B5B5B;
      line-height: 1.5em;
      text-indent: 2em;
    }
  }
}
@media(min-width: 1229px){
  .servicesList{
    width: 1200px;
    margin: 40px auto;
    li{
      .imageWrap{
        width: 260px;
        height: 260px;
        border-radius: 12px;
      }
      h3{
        padding: 30px 0 15px 0;
        font-size: 20px;
      }
      p{
        font-size: 14px;
      }
    }
  }
}
</style>