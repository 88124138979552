<template>
  <section class="solution">
    <MobileSectionTitle>解决方案</MobileSectionTitle>
    <div class="itemList">
      <div class="item" v-for="item in itemList" :key="item.name">
        <img :src="require(`@/assets/img/${item.img}`)" :alt="item.name">
        <h3>{{ item.name }}</h3>
        <p>{{ item.description }}</p>
        <router-link class="more" :to="`/solutions/${item.path}`">
          <span>了解更多</span>
          <i class="el-icon-right"/>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import MobileSectionTitle from '@/components/mobile/public/MobileSectionTitle.vue'

export default {
  name: "solution",
  components: {MobileSectionTitle},
  data(){
    return {
      itemList: [
        {
          name: "平台建设方案",
          description: "实现教育现代化离不开教育信息化，信息化要服务于教育现代化。教学信息化平台建设以学习者为中心，构建“教”与“学”过程。同时构建能够满足教学资源建设长期持续发展的应用框架，实现平台支撑的集中化。教师应用平台可建设课程资源，开展教学管理，备课授课、指导学生学习、批改作业及交流答疑等教学活动。线上线下相结合的混合教学模式，建成时时可教、处处能教的教学场景。",
          img: '平台建设方案.svg',
          path: "platformBuilding"
        },
        {
          name: "课程建设方案",
          description: "实现教育现代化离不开教育信息化，信息化要服务于教育现代化。信息化课程建设以学习者为中心，构建教学资源和教学过程。根据教育部关于印发《教育信息化2.0行动计划》的通知要实现从专用资源向大资源转变；从提升学生信息技术应用能力、向提升信息技术素养转变；从应用融合发展，向创新融合发展转变。",
          img: '课程建设方案.svg',
          path: "courseBuilding"
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.solution {
  background: rgba($theme-color, 0.02);
  margin-top: 20px;
  padding: 28px 20px 12px;
}

.itemList {
  padding: 24px 0;
}

.item {
  box-sizing: border-box;
  margin: 0 auto 24px;
  width: 80%;
  background: $white;
  border-radius: 8px;
  padding: 20px 24px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  > img {
    width: 20%;
    min-width: 80px;
  }

  > h3 {
    margin: 16px;
  }

  > p {
    text-align: left;
    color: $text-gray;
    line-height: 1.4;
    font-size: 13px;
    margin-bottom: 16px;
    //下面这四行作用，多行文本超出4行显示省略号
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }


  .more {
    color: $theme-color;

    span {
      padding-right: 4px;
    }
  }
}
</style>