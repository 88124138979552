<template>
<div class="wrapper">
  <img src="../../assets/img/Courses/InternationalTradePractice_1.jpg" alt="InternationalTradePractice_1">
</div>
</template>

<script>
export default {
  name: "InternationalTradePractice"
}
</script>

<style scoped lang="scss">
.wrapper{
  img{
    width: 100%;
  }
}
</style>
