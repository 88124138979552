<template>
  <section class="container">
    <h2 class="title">公司资质</h2>
    <div class="content">
      <p>
        公司注重自主研发，平台底层技术完全自主搭建、自主设计。围绕“教与学”的过程及数字化课程建设和数字出版领域拥有发明专利与软件著作权等近50项，其中发明专利4项。公司将继续为推动行业蓬勃发展做出更大贡献。
      </p>
    </div>

    <div class="images">
      <div class="left">
        <img src="@/assets/img/About/company_cerone.png" alt="高新技术企业证书">
        <img src="@/assets/img/About/company_certwo.png" alt="出版物经营许可证">
        <img src="@/assets/img/About/company_certhree.png" alt="等保备案证明">
        <img src="@/assets/img/About/company_cerfour.png" alt="教育移动互联网应用程序备案">
      </div>
      <div class="right">
        <img src="@/assets/img/About/company_cerfive.png" alt="商标注册证">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "qualification"
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.container {
  padding: 20px;
}

.title {
  margin-bottom: 16px;
}

.content {
  > p {
    color: $text-gray;
    text-indent: 2em;
    line-height: 1.5;

    & + p {
      margin-top: 16px;
    }
  }
}

.images {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  .left {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    max-width: 400px;
    transform: translateY(6px);

    img {
      width: 45%;
      padding-bottom: 12px;
    }
  }

  .right {
    flex-grow: 1;

    > img {
      height: 160px;
    }
  }
}
</style>