<template>
  <div>
    <div class="wrap" @mouseenter="numberVisible=true" @mouseleave="numberVisible=false">
      <transition name="el-fade-in-linear">
        <div class="contactBox" v-show="numberVisible">
          <h3>联系我们</h3>
          <div class="phone">
            <img src="../assets/img/ic_phone.svg" alt="ic_phone">
            <span>欢迎致电</span>
            <a href="tel:13794343572">13794343572</a>
          </div>
          <div class="email">
            <img src="../assets/img/ic_email.svg" alt="ic_email">
            <span>公司邮箱</span>
            <a href="mailto:ranye@ranyetech.com">ranye@ranyetech.com</a>
          </div>
        </div>
      </transition>
      <img class="icon" src="../assets/img/home_contact.svg" alt="Contact">
      <span>联系我们</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      numberVisible: false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 86px;
  height: 86px;
  background: linear-gradient(155deg, #C30D23 0%, #E31E49 100%);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: fixed;
  right: 42px;
  bottom: 120px;
  z-index: 10;

  img {
    width: 39px;
  }

  span {
    padding-top: 10px;
  }
}

.contactBox {
  box-sizing: border-box;
  color: black;
  width: 278px;
  height: 240px;
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid;
  border-image: linear-gradient(135deg, rgba(233, 239, 255, 1), rgba(233, 239, 255, 1)) 1 1;
  position: absolute;
  top: -155px;
  left: -300px;
  padding: 28px 29px 22px 15px;

  h3 {
    font-size: 22px;
    color: #373636;
    line-height: 30px;
  }
}

.contactBox > .phone {
  margin-top: 20px;

  img {
    width: 15px;
    transform: translateY(3px);
  }

  span {
    margin-left: 9px;
    font-size: 14px;
    color: #373636;
    line-height: 19px;
  }

  a {
    display: block;
    padding-top: 7px;
    font-size: 22px;
    color: #373636;
    line-height: 30px;
    letter-spacing: 2px;
  }
}

.contactBox > .email {
  margin-top: 20px;

  img {
    width: 15px;
    transform: translateY(3px);
  }

  span {
    margin-left: 9px;
    font-size: 14px;
    color: #373636;
    line-height: 19px;
  }

  a {
    display: block;
    padding-top: 7px;
    font-size: 22px;
    color: #373636;
    line-height: 30px;
  }
}
</style>