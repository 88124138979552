<template>
  <div>
    <el-row :gutter="16">
      <el-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" v-for="item in itemList" :key="item.id">
        <div class="item">
          <img :src="item.iconUrl" :alt="`${item.title}_icon`">
          <h4>{{ item.title }}</h4>
          <p>{{ item.description }}</p>
        </div>
      </el-col>
    </el-row>
    <div class="moreWrapper">
      <router-link class="more" to="/digitalPlatform/industryDynamics">
        了解更多 <img src="@/assets/img/home_arrow.svg" alt="home_arrow">
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "digitalPlatform",
  data(){
    return {
      itemList: [
        {
          id: 'industryDynamics',
          iconUrl: require('@/assets/img/Platform-Icon/行企动态.svg'),
          title: '行企动态系统',
          description: '平台把国家教育政策和行业动态及重大新闻事件融入各个专业的每门课程。教育政策和行企动态实时更新，让教师和学生在“教与学”的过程中，及时了解相关政策与行业动态，以便更好地辅助教师教学及学生学习。'
        },
        {
          id: 'courseBuilding',
          iconUrl: require('@/assets/img/Platform-Icon/课堂建设.svg'),
          title: '课程建设系统',
          description: '平台为课程共建共享提供技术支撑，支持各类教 学资源建设(文本资源、音视频资源、考核练习资源等)、课堂实训资源建设(在线模拟实训、交互式实训等)及其他课程资源建设(教学拓展资源、课堂活动资源等)，上传平台的所有资源均可进行区块链存证，保护作者创作版权。'
        },
        {
          id: 'teachingPreparation',
          iconUrl: require('@/assets/img/Platform-Icon/教学备课.svg'),
          title: '教学备课系统',
          description: '平台备课分为:备教学内容和备管教方法。(1)备教学内容教师可依据班级学情及具体学生学情，进行个性化备课，因材施教。教师可在平台现存课程的基础上，对课程资源及实训内容进行在线编辑，灵活调整知识结构，以便于课堂教学。同时，也可添加新的教学资源，丰富教学内容。(2)备管教方法备课堂教学活动和教学方法。课堂以学生为中心，根据过往学生在学习中提出的问题和教学活动与教学组织，提前给予教师反馈，提升课堂教学效果。'
        },
        {
          id: 'teaching',
          iconUrl: require('@/assets/img/Platform-Icon/课堂教学.svg'),
          title: '课堂教学系统',
          description: '根据备课内容组织并实施教学，所有教学行为都可采集数据，为及时了解并掌握课堂教学质量和效果提供有力支撑。课堂教学可实现随教随练，丰富的课堂活动设置可推动学生深度参与课堂教学，形成教学共同体。'
        },
        {
          id: 'learning',
          iconUrl: require('@/assets/img/Platform-Icon/学生学习.svg'),
          title: '学生学习系统',
          description: '平台建有完整的课程结构，丰富的课程资源、实训训练、拓展资源等学习资料。学生可通过任意终端进行自主学习;同时还可根据教师课堂教学要求进行学习，所有学习轨迹都可进行数据采集，生成学习周报，及时掌握学习成绩和学习状态。'
        },
        {
          id: 'teachingInteraction',
          iconUrl: require('@/assets/img/Platform-Icon/教学互动.svg'),
          title: '教学互动系统',
          description: '平台提供各种教学场景互动工具，教师可在课前、课中、课后通过任意终端与学生进行跨时空交流互动，提高学生的参与感。教师可在课前、课中、课后发布所需的学习任务、学习活动及答疑解惑，互动过程中产生的数据均可作为过程考核的一部分。'
        },
        {
          id: 'training',
          iconUrl: require('@/assets/img/Platform-Icon/课堂实训.svg'),
          title: '课堂实训系统',
          description: '平台提供实训底层技术，帮助教师在讲解专业基础知识的同时渗透对应的实训技能，达到一体化教学的目的，提升教学效果;帮助学生边学边练，提高学习效率。'
        },
        {
          id: 'schoolworkTest',
          iconUrl: require('@/assets/img/Platform-Icon/作业试卷.svg'),
          title: '作业试卷系统',
          description: '教师可以利用平台已有题库，或者一键导入自己的题库，组卷、发布作业或考试，平台自动批改。每道题与知识点关联，可依据知识点、题型、难易度、使用次数和错误率组卷出题。平台还可以智能推送与错题相似的题型和知识点，以巩固学习成效。'
        },
      ],
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.item {
  background: #FDFBFC;
  border: 1px dashed #EBEBEB;
  height: 100px;
  padding: 12px;
  margin-bottom: 16px;

  > img {
    height: 28px;
  }

  > h4 {
    line-height: 2;
  }

  > p {
    font-size: 13px;
    line-height: 1.5;
    //下面这四行作用，多行文本超出2行显示省略号
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.moreWrapper {
  color: $theme-color;
  text-align: center;
  margin-top: 8px;
}
</style>