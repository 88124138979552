<template>
  <div class="wrap" @mouseleave="numberVisible=false" @click="toggleVisible" :style="{bottom: `${bottomHeight}px`}">
    <transition name="el-fade-in-linear">
      <div class="contactBox" v-show="numberVisible">
        <i class="close el-icon-close" @click.stop="numberVisible=false"/>
        <h3>联系我们</h3>
        <div class="phone">
          <img src="@/assets/img/ic_phone.svg" alt="ic_phone">
          <span>欢迎致电</span>
          <a href="tel:13794343572">13794343572</a>
        </div>
        <div class="email">
          <img src="@/assets/img/ic_email.svg" alt="ic_email">
          <span>公司邮箱</span>
          <a href="mailto:ranye@ranyetech.com">ranye@ranyetech.com</a>
        </div>
      </div>
    </transition>
    <img class="icon" src="@/assets/img/home_contact.svg" alt="Contact">
    <span>联系我们</span>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  computed: {
    bottomHeight(){
      return ((window.screen.availHeight - window.innerHeight) / 2) + 80 || 100
    }
  },
  data(){
    return {
      numberVisible: false
    }
  },
  methods: {
    toggleVisible(){
      this.numberVisible = !this.numberVisible
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/Variable.scss";

.close {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 20px;
}

.wrap {
  width: 64px;
  height: 64px;
  background: linear-gradient(155deg, $theme-color 0%, rgba($theme-color, 0.8) 100%);
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 10;

  img {
    width: 24px;
  }

  span {
    font-size: 12px;
    padding-top: 10px;
  }
}

.contactBox {
  box-sizing: border-box;
  color: $text-black;
  width: 220px;
  background: $white;
  border-radius: 8px;
  border: 1px solid rgba($gray, 0.3);
  position: absolute;
  top: -190px;
  right: 0;
  padding: 20px;

  h3 {
    font-size: 16px;
  }
}

.contactBox > .phone, .contactBox > .email {
  margin-top: 8px;

  img {
    width: 14px;
    transform: translateY(3px);
  }

  span {
    margin-left: 4px;
    font-size: 12px;
    line-height: 19px;
  }

  a {
    display: block;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 1px;
  }
}
</style>