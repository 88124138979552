<template>
  <div class="home">
    <header>
      <div class="navWrap">
        <div class="logo">
          <img src="../assets/img/home_logo.png" alt="logo" class="logoImage">
        </div>
        <ul class="topNav">
          <li v-for="(item,index) in topNavItems" :key="index" :class="{'selected': currentComp === item.name}"
              @click="jumpTo(item.name)">{{ item.title }}
          </li>
        </ul>
        <el-button type="primary" class="login" @click="openNewPage('https://web.ranyetech.com/login.html')">
          登录
        </el-button>
      </div>
    </header>
    <el-scrollbar style="height:100%" ref="scrollBar">
      <el-backtop target=".el-scrollbar__wrap" :bottom="20">
        <img src="../assets/img/home_top.png" alt="返回顶部" class="topIcon">
      </el-backtop>
      <ContactUs/>
      <Portal id="portal"/>
      <Company id="company"/>
      <Product id="product"/>
      <Solution id="solution"/>
      <Download id="download"/>
      <footer>
        <div class="parent">
          <div class="div1"><img src="../assets/img/云指课堂logo.png" alt="logo"></div>
          <div class="div2"><p>广州然也教育科技有限公司</p></div>
          <div class="div3"><p>粤ICP备17017477号-1</p></div>
          <div class="div4"><h4 class="title">联系我们</h4></div>
          <div class="div5"><a href="tel:020-22042958">020-22042958</a></div>
          <div class="div6"><a href="mailto:ranye@ranyetech.com">ranye@ranyetech.com</a></div>
          <div class="div7"><h4 class="title">公司地址</h4></div>
          <div class="div8"><p>广州市天河区高普路138号京华信息西座205</p></div>
          <div class="div9">
            <img src="../assets/img/ic_govRecord.png" alt="ic_govRecord"/>
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602007669" target="_blank">
              粤公网安备 44010602007669号
            </a>
          </div>
          <div class="div10"><img src="../assets/img/home_barcode.png" alt="公众号二维码"></div>
        </div>
      </footer>
    </el-scrollbar>
  </div>
</template>

<script>
import Portal from '../components/Home/Portal.vue'
import Company from '../components/Home/Company.vue'
import Product from '../components/Home/Product.vue'
import Solution from '../components/Home/Solution.vue'
import Download from '../components/Home/Download.vue'
import ContactUs from "../components/ContactUs.vue"

export default {
  name: 'Home',
  components: {
    ContactUs, Portal, Company, Product, Solution, Download
  },
  data() {
    return {
      currentComp: 'portal',
      topNavItems: [
        {name: 'portal', title: '首页'},
        {name: 'company', title: '公司介绍'},
        {name: 'product', title: '产品服务'},
        {name: 'solution', title: '解决方案'},
        {name: 'download', title: '软件下载'}
      ],
      pageOffsetHeight: undefined
    }
  },
  methods: {
    jumpTo(id) {
      let targetElement = document.getElementById(id)
      targetElement.scrollIntoView({behavior: "smooth"})
    },
    openNewPage(url) {
      window.open('_blank').location = url;
    }
  },
  mounted() {
    let _this = this
    let scrollBarElement = this.$refs.scrollBar.wrap
    let pageOffsetHeight = scrollBarElement.firstElementChild.offsetHeight
    this.pageOffsetHeight = pageOffsetHeight
    // 监听滚动条滚动位置scrollTop，滚动到页面特定比例位置，修改currentComp值，实现顶部导航高亮
    window.onresize = () => {
      return (() => {
        pageOffsetHeight = scrollBarElement.firstElementChild.offsetHeight
        _this.pageOffsetHeight = Math.round(pageOffsetHeight / 1.295) // 不除1.295会造成点击高亮不准的问题
      })()
    }
    scrollBarElement.onscroll = () => {
      let scrollTop = scrollBarElement.scrollTop
      // 下面的0.17的就是表示当滚动条在0%~17%的位置时，首页按钮高亮。
      if (scrollTop < (this.pageOffsetHeight * 0.15)) {
        this.currentComp = 'portal'
      } else if (scrollTop >= (this.pageOffsetHeight * 0.15) && scrollTop < (this.pageOffsetHeight * 0.40)) {
        this.currentComp = 'company'
      } else if (scrollTop >= (this.pageOffsetHeight * 0.40) && scrollTop < (this.pageOffsetHeight * 0.65)) {
        this.currentComp = 'product'
      } else if (scrollTop >= (this.pageOffsetHeight * 0.65) && scrollTop < (this.pageOffsetHeight * 0.90)) {
        this.currentComp = 'solution'
      } else {
        this.currentComp = 'download'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/Variable.scss";

@mixin footer-text {
  font-size: 1.2vw;
  color: $gray;
  line-height: 3em;
}

@mixin footer-title {
  font-size: 1.6vw;
  color: #373636;
  line-height: 2.5em;
}

// 修改滚动条
.home {
  position: relative;
  height: calc(100vh - 22px);
  background: white;
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

// 回到顶部按钮样式
::v-deep .el-backtop, .el-backtop:hover {
  background: #fff;
  width: 86px;
  height: 86px;
  border: 1px solid $theme-color;
  border-radius: 10px;
  color: $theme-color;
  font-family: DIN Condensed Bold, "Microsoft YaHei UI", serif;
  box-shadow: 0 0 11px 0 rgba($gray, 0.5);

}

.topIcon {
  width: 35px;
}

.navWrap {
  width: 98vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 1% 0;
  background: white;
  box-shadow: 10px -15px 50px $gray;
  z-index: 10;

  .logoImage {
    width: 116px;
    height: 44px;
  }

  .topNav {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;

    li {
      margin: 0;
      color: $gray;
      padding: 0 3vw;
      cursor: pointer;
    }

    li.selected {
      font-family: Microsoft YaHei UI Semibold, "Microsoft YaHei UI", serif;
      color: $theme-color;
    }
  }

  .login {
    background: $theme-color;
    opacity: 0.94;
    border: none;
    border-radius: 4px;
    width: 74px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    padding: 0;
    transition: box-shadow 0.5s;
    &:hover{
      box-shadow: 0 3px 10px rgba($theme-color, .35);
      transition: box-shadow 0.3s;
    }
  }
}

aside {
  position: fixed;
  bottom: 5vh;
  right: 5vw;

  .toTopButton {
    margin-top: 20px;
  }
}

footer {
  width: 82vw;
  min-width: 700px;
  margin: 0 auto 10vw auto;

  p, a {
    @include footer-text;
  }

  .title {
    @include footer-title;
    margin-bottom: 0.5em;
  }

  .parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr) 1.5fr 1fr;
    grid-template-rows: repeat(3, 1fr);

    div {
      height: 4vw;
    }

    .div1 {
      grid-area: 1 / 1 / 2 / 2;
    }

    .div2 {
      grid-area: 2 / 1 / 3 / 2;
    }

    .div3 {
      grid-area: 3 / 1 / 4 / 2;
    }

    .div4 {
      grid-area: 1 / 2 / 2 / 3;
    }

    .div5 {
      grid-area: 2 / 2 / 3 / 3;
    }

    .div6 {
      grid-area: 3 / 2 / 4 / 3;
    }

    .div7 {
      grid-area: 1 / 3 / 2 / 4;
    }

    .div8 {
      grid-area: 2 / 3 / 3 / 4;
    }

    .div9 {
      grid-area: 3 / 3 / 4 / 4;
      display: flex;
      //justify-content: center;
      align-items: center;
    }

    .div10 {
      grid-area: 1 / 4 / 4 / 5;
      height: 100%;
    }
  }

  .div1 > img {
    width: 60%;
    margin-bottom: 1vw;
  }

  .div10 {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 9vw;
    }
  }
}

@media (min-width: 1229px) {
  @mixin footer-text-adaptive {
    font-size: 15px;
    color: $gray;
    line-height: 3em;
  }
  @mixin footer-title-adaptive {
    font-size: 20px;
    color: #373636;
    line-height: 2.5em;
  }
  header {
    height: 58px;
    background: white;
    box-shadow: 10px -15px 50px #dddddd;
    z-index: 11;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
  }
  .navWrap {
    width: 1100px;
    border-radius: 10px;
    box-shadow: none;

    .topNav {
      margin-left: 200px;

      li {
        font-size: 14px;
        padding: 0 0 0 49px;
      }
    }
  }
  footer {
    width: 1000px;
    margin: 0 auto 120px auto;

    p, a {
      @include footer-text-adaptive;
    }

    .title {
      @include footer-title-adaptive;
      margin-bottom: 0.5em;
    }

    .parent > div {
      height: 50px;
    }

    .div10 {
      img {
        width: 7vw;
      }
    }
  }
}
</style>
