<template>
  <div class="platform">
    <p class="description">利用新一代ICT技术，将具体的“教与学”场景数字化。平台以优质内容为核心，连接师生、
      贯穿教学全过程、构建教学闭环，打破教育教学与信息化“两张皮”现象，真正实现一体化教学。整个教学过程融入AI和区块链技术，可实现教学数据的自由流转存证，最大限度发挥应用价值，
      保护创作版权。课程资源建设不受专业限制，均可在平台实现智能化系统化、教学化及作品化。</p>
    <el-dialog :visible.sync="dialogVisible" :show-close="false" width="90vw">
      <img @click="dialogVisible=false" class="closeButton"
           src="../../../assets/img/Platform-Icon/Dialog-Images/ic_close.svg" alt="closeIcon">
      <el-menu :default-active="currentItem.component"
               mode="horizontal"
               @select="handleSelect"
               active-text-color="#C30D23">
        <el-menu-item :index="item.component" v-for="item in itemsList" :key="item.title">
          {{ item.title }}
        </el-menu-item>
      </el-menu>
      <DialogTemplate :itemInfo="currentItem"/>
    </el-dialog>

    <el-row :gutter="20" v-for="(columns,rowIndex) in layoutList" :key="rowIndex">
      <el-col :span="8" v-for="(item,index) in columns" :key="index">
        <div class="wrapper" :id="item.id" @mouseenter="enter" @mouseleave="leave">
          <div class="in-active">
            <img :src="item.iconUrl" :alt="`${item.title}_icon`">
            <h4>{{ item.title }}系统</h4>
            <p>{{ item.description }}</p>
          </div>
          <transition name="el-fade-in-linear">
            <div class="active" v-show="detailVisible===item.id">
              <h4>{{ item.title }}系统</h4>
              <p>{{ item.description }}</p>
              <el-button size="mini" @click="openDialog(item)">查看更多</el-button>
            </div>
          </transition>
        </div>
      </el-col>
    </el-row>
    <div class="more"><span @click="openDialog(itemsList[9])">查看更多</span><img  @click="openDialog(itemsList[9])" src="@/assets/img/home_arrow.svg" alt="home_arrow"></div>
  </div>
</template>

<script>
import DialogTemplate from "./InfomationPlatform/DialogTemplate";

export default {
  name: "InformationPlatform",
  components: {
    DialogTemplate
  },
  computed: {
    layoutList() {
      // 九宫格，需要将 itemsList 变为下面这样的结构
      // [
      //   [{}，{}，{}],
      //   [{}，{}，{}],
      //   [{}，{}，{}]
      // ]
      let result = []
      let originList = [...this.itemsList]
      for (let i = 0; i < 3; i++) {
        let rowList = originList.splice(0, 3)
        result.push(rowList)
      }
      return result
    },
    currentComponent() {
      return this.currentItem.component
    }
  },
  data() {
    return {
      detailVisible: undefined,
      dialogVisible: false,
      itemsList: [
        {
          id: 'industryDynamics',
          iconUrl: require('../../../assets/img/Platform-Icon/行企动态.svg'),
          imageUrl: require('../../../assets/img/Platform-Icon/Dialog-Images/bg_行企动态.png'),
          title: '行企动态',
          description: '平台把国家教育政策和行业动态及重大新闻事件融入各个专业的每门课程。教育政策和行企动态实时更新，让教师和学生在“教与学”的过程中，及时了解相关政策与行业动态，以便更好地辅助教师教学及学生学习。',
          component: 'IndustryDynamics',
          feature: {
            title: '特点',
            content: '在“教与学”的过程中，师生能及时了解相关政策、行业动态及企业用人需求。',
          }
        },
        {
          id: 'courseBuilding',
          iconUrl: require('../../../assets/img/Platform-Icon/课堂建设.svg'),
          imageUrl: require('../../../assets/img/Platform-Icon/Dialog-Images/bg_课程建设.png'),
          title: '课程建设',
          description: '平台为课程共建共享提供技术支撑，支持各类教 学资源建设(文本资源、音视频资源、考核练习资源等)、课堂实训资源建设(在线模拟实训、交互式实训等)及其他课程资源建设(教学拓展资源、课堂活动资源等)，上传平台的所有资源均可进行区块链存证，保护作者创作版权。',
          component: 'CourseBuilding',
          feature: {
            title: '功能',
            content: ['可建文本资源', '媒体资源', 'H5交互式资源', '考核资源'],
          }
        },
        {
          id: 'teachingPreparation',
          iconUrl: require('../../../assets/img/Platform-Icon/教学备课.svg'),
          imageUrl: require('../../../assets/img/Platform-Icon/Dialog-Images/bg_教学备课.png'),
          title: '教学备课',
          description: '平台备课分为:备教学内容和备管教方法。(1)备教学内容教师可依据班级学情及具体学生学情，进行个性化备课，因材施教。教师可在平台现存课程的基础上，对课程资源及实训内容进行在线编辑，灵活调整知识结构，以便于课堂教学。同时，也可添加新的教学资源，丰富教学内容。(2)备管教方法备课堂教学活动和教学方法。课堂以学生为中心，根据过往学生在学习中提出的问题和教学活动与教学组织，提前给予教师反馈，提升课堂教学效果。',
          component: 'TeachingPreparation',
          feature: {
            title: '功能',
            content: '标准化的课程内容，可添加个性化的资源，可对全部教学场景进行教学设计。',
          }
        },
        {
          id: 'teaching',
          iconUrl: require('../../../assets/img/Platform-Icon/课堂教学.svg'),
          imageUrl: require('../../../assets/img/Platform-Icon/Dialog-Images/bg_课堂教学.png'),
          title: '课堂教学',
          description: '根据备课内容组织并实施教学，所有教学行为都可采集数据，为及时了解并掌握课堂教学质量和效果提供有力支撑。课堂教学可实现随教随练，丰富的课堂活动设置可推动学生深度参与课堂教学，形成教学共同体。',
          component: 'Teaching',
          feature: {
            title: '特点',
            content: ['知识点讲解', '发布教学活动', '发布课程实训', '开展课堂互动'],
          }
        },
        {
          id: 'learning',
          iconUrl: require('../../../assets/img/Platform-Icon/学生学习.svg'),
          imageUrl: require('../../../assets/img/Platform-Icon/Dialog-Images/bg_学生学习.png'),
          title: '学生学习',
          description: '平台建有完整的课程结构，丰富的课程资源、实训训练、拓展资源等学习资料。学生可通过任意终端进行自主学习;同时还可根据教师课堂教学要求进行学习，所有学习轨迹都可进行数据采集，生成学习周报，及时掌握学习成绩和学习状态。',
          component: 'Learning',
          feature: {
            title: '功能',
            content: ['参与教学活动', '线上线下混合式学习', '参与课堂实训', '对教学进行评价'],
          }
        },
        {
          id: 'teachingInteraction',
          iconUrl: require('../../../assets/img/Platform-Icon/教学互动.svg'),
          imageUrl: require('../../../assets/img/Platform-Icon/Dialog-Images/bg_教学互动.png'),
          title: '教学互动',
          description: '平台提供各种教学场景互动工具，教师可在课前、课中、课后通过任意终端与学生进行跨时空交流互动，提高学生的参与感。教师可在课前、课中、课后发布所需的学习任务、学习活动及答疑解惑，互动过程中产生的数据均可作为过程考核的一部分。',
          component: 'TeachingInteraction',
          feature: {
            title: '功能',
            content: '调查问卷、通知、线上线下答题、讨论、选人、点名 ……',
          }
        },
        {
          id: 'training',
          iconUrl: require('../../../assets/img/Platform-Icon/课堂实训.svg'),
          imageUrl: require('../../../assets/img/Platform-Icon/Dialog-Images/bg_课堂实训.png'),
          title: '课堂实训',
          description: '平台提供实训底层技术，帮助教师在讲解专业基础知识的同时渗透对应的实训技能，达到一体化教学的目的，提升教学效果;帮助学生边学边练，提高学习效率。',
          component: 'Training',
          feature: {
            title: '功能',
            content: '有教有练，在一体化教学实践中驱动并完成教学任务。',
          }
        },
        {
          id: 'schoolworkTest',
          iconUrl: require('../../../assets/img/Platform-Icon/作业试卷.svg'),
          imageUrl: require('../../../assets/img/Platform-Icon/Dialog-Images/bg_作业试卷.png'),
          title: '作业试卷',
          description: '教师可以利用平台已有题库，或者一键导入自己的题库，组卷、发布作业或考试，平台自动批改。每道题与知识点关联，可依据知识点、题型、难易度、使用次数和错误率组卷出题。平台还可以智能推送与错题相似的题型和知识点，以巩固学习成效。',
          component: 'SchoolworkTest',
          feature: {
            title: '特点',
            content: ['发布作业', '发布考核', '试题组卷'],
          }
        },
        {
          id: 'assessment',
          iconUrl: require('../../../assets/img/Platform-Icon/考核评价.svg'),
          imageUrl: require('../../../assets/img/Platform-Icon/Dialog-Images/bg_考核评价.png'),
          title: '考核评价',
          description: '平台存储所有“教与学”的全部过程和考核结果，为教学评价提供科学依据。教师对学生学习过程进行考核，学生对教师的教学内容和教学方法、态度进行评价;平台记录“教与学”的全部过程和行为轨迹，通过大数据分析 出具教学报告和学生学习报告;同时进行区块链存证，所有教学过程和考核结果可溯源，确保其真实性，实现“教与学”的过程性评价与结果性评价等多元评价体系。',
          component: 'Assessment',
          feature: {
            title: '功能',
            content: ['课堂考核', '课堂活动考核', '线上考核', '实训考核'],
          }
        },
        {
          id: 'data',
          iconUrl: require('../../../assets/img/Platform-Icon/数据中心.svg'),
          imageUrl: require('../../../assets/img/Platform-Icon/Dialog-Images/bg_数据中心.png'),
          title: '数据中心',
          description: '平台对“教与学”的全场景、全过程数据进行采集，捕捉操作痕迹，形成教学大数据并及时分析，形成教学预警反馈机制，对于保障课堂教学质量、改进教学管理有显著促进作用。',
          component: 'Data',
          feature: {
            title: '功能',
            content: '全场景数据采集，捕捉操作过程，形成结果性数据报告和过程性数据反馈，对教师和学生进行人物画像。',
          }
        }
      ],
      currentItem: {
        id: 'industryDynamics',
        iconUrl: require('../../../assets/img/Platform-Icon/行企动态.svg'),
        imageUrl: require('../../../assets/img/Platform-Icon/Dialog-Images/bg_行企动态.png'),
        title: '行企动态',
        description: '平台把国家教育政策和行业动态及重大新闻事件融入各个专业的每门课程。教育政策和行企动态实时更新，让教师和学生在“教与学”的过程中，及时了解相关政策与行业动态，以便更好地辅助教师教学及学生学习。',
        component: 'IndustryDynamics',
        feature: {
          title: '特点',
          content: '在“教与学”的过程中，师生能及时了解相关政策、行业动态及企业用人需求。',
        }
      },
    }
  },
  methods: {
    enter(e) {
      this.detailVisible = e.target.id
    },
    leave() {
      this.detailVisible = undefined
    },
    openDialog(item) {
      this.dialogVisible = true
      this.currentItem = item
    },
    handleSelect(key) {
      this.currentItem = this.itemsList.filter(item => item.component === key)[0]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/Variable.scss";

.platform {
  width: 90vw;
  margin: 0 auto;
}

.description {
  font-size: 1vw;
  font-family: "Microsoft YaHei UI", serif;
  color: #5B5B5B;
  line-height: 2em;
  text-indent: 2em;
  padding: 0.4vw 0 4vw 0;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
}

::v-deep .el-dialog {
  border-radius: 0.5vw;

  .el-dialog__header {
    padding: 0;
  }
}

.el-menu {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.el-menu::-webkit-scrollbar {
  display: none;
}

.el-menu-item {
  padding: 0 1.2vw;
}

.wrapper {
  position: relative;
  margin-bottom: 2vw;
  padding: 1.5vw 2vw;
  background: #FDFBFC;
  border: 1px dashed #EBEBEB;

  p {
    //下面这四行作用，多行文本超出2行显示省略号
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.in-active {
  img {
    height: 3vw;
  }

  h4 {
    font-size: 1.4vw;
    padding: 1vw 0;
  }

  p {
    font-size: 1vw;
    font-family: MicrosoftYaHei, "Microsoft YaHei UI", sans-serif;
    color: #5B5B5B;
    line-height: 1.5rem;
  }
}

.active {
  width: 90%;
  height: 77.5%;
  background: #FDFBFC;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px dashed $theme-color;
  padding: 5%;

  h4 {
    font-size: 1.4vw;
    padding: 1vw 0;
  }

  p {
    font-size: 1vw;
    font-family: MicrosoftYaHei, "Microsoft YaHei UI", sans-serif;
    color: #5B5B5B;
    line-height: 1.5rem;
    margin-bottom: 0.5vw;
  }

  .el-button {
    position: absolute;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    background: $theme-color;
    color: white;
    &:hover{
      border-color: $gray;
    }
  }
}

.more{
  text-align: center;
  color: $theme-color;
  >span{
    margin-right: 10px;
    cursor: pointer;
  }
  >img{
    cursor: pointer;
  }
}

@media(min-width: 1229px) {
  .platform {
    width: 1120px;
    margin: 0 auto;
  }

  .description {
    font-size: 13px;
    padding: 5px 0 25px 0;
  }
  ::v-deep .el-dialog {
    border-radius: 10px;
    width: 1100px !important;
  }

  .wrapper {
    margin-bottom: 24px;
    padding: 18px 24px;
  }
  .in-active {
    img {
      height: 36px;
    }

    h4 {
      font-size: 18px;
      padding: 12px 0;
    }

    p {
      font-size: 12px;
    }
  }
  .active {
    h4 {
      font-size: 18px;
      padding: 12px 0;
    }

    p {
      font-size: 12px;
      margin-bottom: 12px;
    }
  }
}
</style>