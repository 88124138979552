<template>
  <section class="company">
    <MobileSectionTitle>公司简介</MobileSectionTitle>
    <div class="introduction">
      广州然也教育科技有限公司，是一家从事智慧教育的国家高新技术企业。公司坚持把信息技术与教育教学实践深度融合，在融合型数字化课程研发、融合型数字化教学平台搭建以及数字出版等领域不断深耕，锐意进取，始终恪守“让科技赋能教育，让教育内容服务用户”的宗旨，利用数字技术，致力构建“教学练考评管”一体化的闭环教育生态系统。
    </div>
    <div class="moreWrapper">
      <router-link class="more" to="/about/introduction">
        了解更多 <i class="el-icon-right"/>
      </router-link>
    </div>

    <div class="customerWrapper">
      <h3>多个客户的信赖之选</h3>
      <el-carousel height="200px"
                   :interval="5000"
                   trigger="click"
                   indicator-position="none"
                   arrow="never"
                   @change="rearrange">
        <el-carousel-item class="imageWrapper" v-for="(school,i) in activeSchoolList" :key="i">
          <div class="images">
            <img v-for="(item,index) in school" :key="index" :src="require('@/assets/img/CarouselSchool/' + item)"
                 :alt="item">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </section>
</template>

<script>
import MobileSectionTitle from '@/components/mobile/public/MobileSectionTitle.vue'

export default {
  name: "company",
  components: {MobileSectionTitle},
  data(){
    return {
      schoolRootList: [
        'home_one.png', 'home_two.png', 'home_three.png', 'home_four.png',
        'home_five.png', 'home_six.png', 'home_seven.png', 'home_eight.png',
        'home_nine.png', 'home_ten.png', 'home_eleven.png', 'home_twelve.png',
        'home_thirteen.png', 'home_fourteen.png', 'home_fifteen.png', 'home_sixteen.png',
        'home_seventeen.png', 'home_eighteen.png', 'home_nineteen.png'
      ],
      activeSchoolList: []
    }
  },
  methods: {
    renderSchoolList(){
      let rootList = JSON.parse(JSON.stringify(this.schoolRootList))
      // 初始化页面时在19个学校中随机删除一个，以维持轮播图每页都有6个学校
      let delIndex = Math.round((rootList.length * Math.random()))
      rootList.splice(delIndex, 1)
      for(let i = 0; i < 3; i++){
        this.activeSchoolList.push(rootList.slice(6 * i, 6 * (i + 1)))
      }
    },
    rearrange(i, j){
      if(j === 2){
        // 当轮播图切换到最后一组，重新触发renderSchoolList函数
        this.renderSchoolList()
      }
    }
  },
  mounted(){
    // 初始化一下
    this.renderSchoolList()
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.company {
  background: rgba($theme-color, 0.01);
  margin-top: 20px;
  padding: 12px 20px;

  .introduction {
    font-size: 16px;
    text-indent: 2em;
    line-height: 1.8;
    margin-top: 12px;
  }

  .moreWrapper {
    color: $theme-color;
    text-align: center;
    margin-top: 16px;
  }

  .customerWrapper {
    margin-top: 32px;

    > h3 {
      text-align: center;
    }

    .images {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-right: -8px;
      padding-top: 16px;
      min-width: 350px;

      img {
        height: 36px;
        max-width: 300px;
        margin-bottom: 8px;
        margin-right: 8px;
        box-shadow: 0 0 6px rgba($black, 0.05);
      }
    }
  }

}
</style>