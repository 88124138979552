<template>
  <div>
    <el-container style="height: 100vh;">
      <el-header style="height: 13vh;">
        <img src="@/assets/img/About/company_header.png/" alt="公司介绍页头">
      </el-header>
      <el-container class="inside-container">
        <el-aside width="200px">
          <el-menu default-active="0" @select="jumpTo" text-color="#B5A3A5" active-text-color="#C30D23">
            <el-menu-item v-for="(menuItem,index) in menuItemsList" :index="index.toString()" :key="index">
              <img :class="{visible:selectedIndex === index.toString()}" src="@/assets/img/About/company_tag.svg/"
                   alt="company_tag">
              <span slot="title">{{ menuItem }}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main style="height:83vh;">
          <el-scrollbar style="height:100%;">
            <main class="content">
              <section id="0" class="introduction">
                <h3>公司简介</h3>
                <el-carousel :interval="5000" arrow="never" height="30vw">
                  <el-carousel-item class="imageItem" v-for="item in 4" :key="item">
                    <!--                    <div class="imageWrapper">-->
                    <img :src="require(`@/assets/img/About/carousel/图${item}.png`)" alt="公司图片">
                    <!--                    </div>-->
                  </el-carousel-item>
                </el-carousel>
                <p>
                  广州然也教育科技有限公司，是一家从事智慧教育的国家高新技术企业。公司坚持把信息技术与教育教学实践深度融合，在融合型数字化课程研发、融合型数字化教学平台搭建以及数字出版等领域不断深耕，锐意进取，始终恪守“让科技赋能教育，让教育内容服务用户”的宗旨，利用数字技术，致力构建“教学练考评管”一体化的闭环教育生态系统。
                </p>
                <p>
                  基于前端数字化课程在教学场景中的应用，移步后端课程研发，融合数字技术与数据应用，构建各专业课程的教学体系模型，建设理实一体、蕴含教学全要素、覆盖教学全过程的教学场景模型;构建“教与学”的闭环教育生态系统，形成教学共同体，进而推动教育数字化升级，服务教育教学全场景，从根本上提高教育教学质量，公司倾力推出融合型数字化课程、融合型数字化教学平台及教学质量管理平台。
                </p>
              </section>
              <section id="1" class="qualification">
                <h3>公司资质</h3>
                <p>
                  公司注重自主研发，平台底层技术完全自主搭建、自主设计。围绕“教与学”的过程及数字化课程建设和数字出版领域拥有发明专利与软件著作权等近50项，其中发明专利4项。公司将继续为推动行业蓬勃发展做出更大贡献。</p>
                <div class="wrapper">
                  <div class="item1">
                    <el-image :src="require('../assets/img/About/company_cerone.png')" alt="高新技术企业证书"/>
                  </div>
                  <div class="item2">
                    <el-image :src="require('@/assets/img/About/company_certwo.png')" alt="出版物经营许可证"/>
                  </div>
                  <div class="item3">
                    <el-image :src="require('@/assets/img/About/company_certhree.png/')" alt="等保备案证明"/>
                  </div>
                  <div class="item4">
                    <el-image :src="require('@/assets/img/About/company_cerfour.png/')" alt="教育移动互联网应用程序备案"/>
                  </div>
                  <div class="item5">
                    <el-image :src="require('@/assets/img/About/company_cerfive.png/')" alt="商标注册证"/>
                  </div>
                </div>
              </section>
              <section id="2" class="patent">
                <h3>公司证书</h3>
                <div class="patentWrapper">
                  <img v-for="(patent,index) in patentList" :key="index" :src="patent" :alt="`证书-${index}`">
                </div>
              </section>
              <section id="3" class="customer">
                <h3>合作案例</h3>
                <div class="customerWrapper">
                  <div v-for="(img,index) in cooperationList" :key="index" :class="`item${index+1}`">
                    <img :src="img" :alt="`合作案例${index+1}`">
                  </div>
                </div>
              </section>
            </main>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>

export default {
  name: "About",
  data() {
    return {
      selectedIndex: '0',
      certificateList: [
        require('../assets/img/About/company_cerone.png'),
        require('../assets/img/About/company_certwo.png'),
        require('../assets/img/About/company_certhree.png'),
        require('../assets/img/About/company_cerfour.png'),
        require('../assets/img/About/company_cerfive.png'),
      ],
      menuItemsList: ['公司简介', '公司资质', '公司证书', '合作案例'],
      patentList: [
        require('@/assets/img/About/官网-证书-实训.png'),
        require('@/assets/img/About/官网-证书-投屏.png'),
        require('@/assets/img/About/官网-证书-出版.png'),
        require('@/assets/img/About/官网-证书-资源库建设平台.png'),
        require('@/assets/img/About/官网-证书-然也信息化教学平台.png'),
        require('@/assets/img/About/官网-证书-资源建设系统.png'),
        require('@/assets/img/About/官网-证书-云指课堂教学资源系统.png'),
        require('@/assets/img/About/官网-证书-信息化平台ios.png'),
      ],
      cooperationList: [
        require('@/assets/img/About/company_caseone.svg'),
        require('@/assets/img/About/company_casetwo.svg'),
        require('@/assets/img/About/company_casethree.svg'),
        require('@/assets/img/About/company_casefour.svg'),
        require('@/assets/img/About/company_casefive.svg'),
        require('@/assets/img/About/company_casesix.svg'),
        require('@/assets/img/About/company_caseseven.svg'),
        require('@/assets/img/About/company_caseeight.svg'),
        require('@/assets/img/About/company_casenine.png'),
        require('@/assets/img/About/company_caseten.svg'),
        require('@/assets/img/About/company_caseelven.png'),
        require('@/assets/img/About/company_casetwelve.png'),
        require("@/assets/img/About/company_thirteen.svg/"),
        require("@/assets/img/About/company_fourteen.png/"),
        require("@/assets/img/About/company_fifteen.png/"),
        require("@/assets/img/About/company_sixteen.svg/"),
        require("@/assets/img/About/company_seventeen.svg/"),
        require("@/assets/img/About/company_eighteen.svg/"),
        require("@/assets/img/About/company_nineteen.png/")
      ],
    }
  },
  methods: {
    jumpTo(index) {
      let selectedElement = document.getElementById(index)
      this.selectedIndex = index
      selectedElement.scrollIntoView({behavior: "smooth"})
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/Variable.scss";

// 滚动条样式
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

// 页头样式
.el-header {
  background: linear-gradient(140deg, #C30D23 0%, #E31E49 100%);
  color: white;
  padding: 0;
  overflow: hidden;

  img {
    margin: 1vw auto 0 auto;
    padding-left: 10vw;
    width: 90vw;
    min-width: 1000px;
  }

}

// 侧边导航栏样式
.el-aside {
  margin-top: 4vh;
  text-align: center;
  line-height: 200px;
}

.el-menu-item:focus, .el-menu-item:hover {
  background: none;
}

.is-active {
  border-right: 2px solid $theme-color;
}

.el-menu-item {
  border-bottom: 1px solid #ebebeb;

  .visible {
    opacity: 1;
  }

  img {
    opacity: 0;
  }

  span {
    padding-left: 2vw;
  }
}

.el-menu-item:nth-last-child(1) {
  border-bottom: none;
}


// 主体内容样式
@mixin content-title {
  color: #373636;
  font-size: 2.1vw;
  padding-bottom: 1em;
}

@mixin content-paragraph {
  font-size: 1.3vw;
  font-family: Microsoft Yahei UI, "Microsoft YaHei UI", serif;
  font-weight: 400;
  color: #5B5B5B;
  text-indent: 2em;
  line-height: 1.6em;
}

.el-main {
  // header的白条消失，需要在.introduction里加padding-top: 4vh;
  //padding: 0 5px 20px 50px;
  padding: 4vh 5px 20px 50px;
  color: #373636;
}

.content {
  width: 80%;
}

.content > .introduction {
  // header的白条消失，需要在.el-main里写成 padding: 4vh 5px 20px 50px;
  //padding-top: 4vh;
  padding-bottom: 5vw;

  h3 {
    @include content-title;
  }

  .imageItem {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      //width: 100%;
      max-height: 99%;
      max-width: 2000px;
    }

  }


  p {
    @include content-paragraph;
    padding-top: 1.8em;
  }
}

.content > .qualification {
  padding-bottom: 5vw;

  h3 {
    @include content-title
  }

  p {
    @include content-paragraph;
    padding-bottom: 2em;
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr) 1.15fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1.5vw;
    grid-row-gap: 1.5vw;

    .item1 {
      grid-area: 1 / 1 / 2 / 2;
    }

    .item2 {
      grid-area: 1 / 2 / 2 / 3;
    }

    .item3 {
      grid-area: 2 / 1 / 3 / 2;
    }

    .item4 {
      grid-area: 2 / 2 / 3 / 3;
    }

    .item5 {
      grid-area: 1 / 3 / 3 / 4;
    }

    div > img {
      width: 100%;
    }
  }
}

.content > .patent {
  padding-bottom: 5vw;

  h3 {
    @include content-title
  }

  .patentWrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    > img {
      width: 23%;
      margin: 6px;
    }
  }
}

.content > .customer {
  h3 {
    @include content-title
  }

  .customerWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0.5vw;
    grid-row-gap: 0.5vw;

    .item1 {
      grid-area: 1 / 1 / 2 / 2;
    }

    .item2 {
      grid-area: 1 / 2 / 2 / 3;
    }

    .item3 {
      grid-area: 1 / 3 / 2 / 4;
    }

    .item4 {
      grid-area: 2 / 1 / 3 / 2;
    }

    .item5 {
      grid-area: 2 / 2 / 3 / 3;
    }

    .item6 {
      grid-area: 2 / 3 / 3 / 4;
    }

    .item7 {
      grid-area: 3 / 1 / 4 / 2;
    }

    .item8 {
      grid-area: 3 / 2 / 4 / 3;
    }

    .item9 {
      grid-area: 3 / 3 / 4 / 4;
    }

    .item10 {
      grid-area: 4 / 1 / 5 / 2;
    }

    .item11 {
      grid-area: 4 / 2 / 5 / 3;
    }

    .item12 {
      grid-area: 4 / 3 / 5 / 4;
    }

    .item13 {
      grid-area: 5 / 1 / 6 / 2;
    }

    .item14 {
      grid-area: 5 / 2 / 6 / 3;
    }

    .item15 {
      grid-area: 5 / 3 / 6 / 4;
    }

    .item16 {
      grid-area: 6 / 1 / 7 / 2;
    }

    .item17 {
      grid-area: 6 / 2 / 7 / 3;
    }

    .item18 {
      grid-area: 6 / 3 / 7 / 4;
    }

    div > img {
      width: 100%;
    }
  }
}

@media (min-width: 1400px) {
  .el-header {
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;

    img {
      padding: 20px 200px 0 200px;
      width: 1000px;
    }
  }
  .el-menu-item > span {
    padding-left: 24px;
  }
  .el-aside {
    margin-left: 150px;
  }
  // 主体内容样式
  @mixin content-title-adaptive {
    color: #373636;
    font-size: 25px;
    padding-bottom: 1em;
  }
  @mixin content-paragraph-adaptive {
    font-size: 16px;
    font-family: Microsoft Yahei UI, "Microsoft YaHei UI", serif;
    font-weight: 400;
    color: #5B5B5B;
    text-indent: 2em;
    line-height: 1.6em;
  }
  .content {
    margin-left: 40px;
    width: 850px;
  }
  .content > .introduction {
    padding-bottom: 60px;

    h3 {
      @include content-title-adaptive;
    }

::v-deep.el-carousel__container{
  height: 450px!important;
}
  ;

    p {
      @include content-paragraph-adaptive;
      padding-top: 1.8em;
    }
  }
  .content > .qualification {
    padding-bottom: 5vw;

    h3 {
      @include content-title-adaptive
    }

    p {
      @include content-paragraph-adaptive;
      padding-bottom: 2em;
    }

    .wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr) 1.15fr;
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 1.5vw;
      grid-row-gap: 1.5vw;

      .item1 {
        grid-area: 1 / 1 / 2 / 2;
      }

      .item2 {
        grid-area: 1 / 2 / 2 / 3;
      }

      .item3 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .item4 {
        grid-area: 2 / 2 / 3 / 3;
      }

      .item5 {
        grid-area: 1 / 3 / 3 / 4;
      }

      div > img {
        width: 100%;
      }
    }
  }
  .content > .patent {
    h3 {
      @include content-title-adaptive
    }
  }
  .content > .customer {
    h3 {
      @include content-title-adaptive
    }

    .customerWrapper {
      grid-column-gap: 6px;
      grid-row-gap: 6px;
    }
  }
}

@media (min-width: 1600px) {
  .el-aside {
    margin-left: 200px;
  }
}

@media (min-width: 1800px) {
  .el-aside {
    margin-left: 300px;
  }
}

@media (min-width: 2000px) {
  .el-aside {
    margin-left: 400px;
  }
}
</style>
