<template>
  <main class="home">
    <MobileContactUs/>
    <section class="cover">
      <img class="slogan" src="@/assets/img/mobile/mobile_home_slogan.png" alt="学在云端，练在指尖">
      <div class="downloadButton" @click="onDownload">立即下载</div>
      <img class="coverBackground" src="@/assets/img/mobile/mobile_home_cover_background.png" alt="封面">
    </section>
    <Company/>
    <Product/>
    <Solution/>
    <footer>
      <div class="contactUs">
        <h4>联系我们</h4>
        <div class="content">
          <span>广州然也教育科技有限公司</span>
          <a href="tel:020-22042958">020-22042958</a>
          <a href="mailto:ranye@ranyetech.com">ranye@ranyetech.com</a>
        </div>
      </div>
      <div class="address">
        <h4>公司地址</h4>
        <div class="content">
          <span>广州市天河区高普路138号京华信息西座205</span>
          <span>粤ICP备17017477号-1</span>
        </div>
      </div>
    </footer>
  </main>
</template>

<script>
import Company from '@/components/mobile/home/company.vue'
import Product from '@/components/mobile/home/product.vue'
import Solution from '@/components/mobile/home/solution.vue'
import MobileContactUs from '@/components/mobile/public/MobileContactUs.vue'

export default {
  components: {MobileContactUs, Product, Company, Solution},
  methods: {
    onDownload(){
      window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.cn.cloudrefers.cloudrefersclassroom&fromcase=40003"
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.home > .cover {
  position: relative;
  padding: 16px;
  height: calc(86vw - 32px);
  max-height: 500px;

  .slogan {
    position: absolute;
    height: 6vw;
    max-height: 40px;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
  }

  .downloadButton {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 24%;
    background: $theme-color;
    padding: 10px 16px;
    border-radius: 4px;
    font-size: 16px;
    color: $white;
  }

  .coverBackground {
    position: absolute;
    width: calc(100% - 32px);
    max-width: 600px;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
  }
}

.home > footer {
  padding: 48px 0;


  .contactUs, .address {
    width: 80%;
    margin: 0 auto;

    > h4 {
      padding-bottom: 12px;
    }

    .content {
      color: $gray;
      display: flex;
      flex-wrap: wrap;

      > span, > a {
        line-height: 1.5;
        padding-right: 24px;
        padding-bottom: 8px;
      }
    }
  }

  .contactUs {
    margin-bottom: 20px;
  }
}
</style>