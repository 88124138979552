<template>
  <div class="DialogTemplate">
    <img class="mainImage" :src="itemInfo.imageUrl" :alt="`${itemInfo.title}_icon`">
    <main>
      <img class="icon" :src="itemInfo.iconUrl" :alt="`${itemInfo.title}_icon`">
      <h3 class="title">{{ itemInfo.title }}</h3>
      <article v-show="itemInfo.title !== '教学备课'">{{ itemInfo.description }}</article>
      <article v-show="itemInfo.title === '教学备课'">
        <p>平台备课分为: 备教学内容和备管教方法。</p>
        <p>(1)备教学内容</p>
        <p>教师可依据班级学情及具体学生学情，进行个性化备课，因材施教。教师可在平台现存课程的基础上，对课程资源及实训内容进行在线编辑，灵活调整知识结构，以便于课堂教学。同时，也可添加新的教学资源，丰富教学内容。</p>
        <p>(2)备管教方法</p>
        <p>备课堂教学活动和教学方法。课堂以学生为中心，根据过往学生在学习中提出的问题和教学活动与教学组织，提前给予教师反馈，提升课堂教学效果。</p>
      </article>
      <div class="feature">
        <div class="title"><span>{{ itemInfo.feature.title }}：</span></div>
        <div class="content" v-if="typeof itemInfo.feature.content === 'string'">{{ itemInfo.feature.content }}</div>
        <div class="content" v-else>
          <span v-for="item in itemInfo.feature.content" :key="item">
            {{ item }}
          </span>
          <span>......</span>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "DialogTemplate",
  props: ['itemInfo'],
}
</script>

<style scoped lang="scss">
@import "~@/assets/Variable.scss";

.DialogTemplate {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.mainImage {
  width: 50%;
  padding: 10%;
}

.DialogTemplate main {
  padding-top: 10%;

  .icon {
    height: 4vw;
    padding-bottom: 8%;
  }

  > .title {
    padding-bottom: 1.7vw;
    font-size: 2.3vw;
    color: #373636;
  }

  article {
    font-size: 1.2vw;
    padding-bottom: 2vw;
    line-height: 1.8;
    padding-right: 5vw;
    text-align: justify;
  }

  .feature {
    font-size: 1.2vw;
    line-height: 1.8;
    display: flex;
    justify-content: space-between;

    .title {
      color: $theme-color;
      white-space: nowrap;

      & > span {
        position: relative;
        padding-left: 1.2em;
      }

      & > span:before {
        content: '';
        background: $theme-color;
        width: 1vw;
        height: 1vw;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .content {
      color: #5B5B5B;
      display: flex;
      flex-wrap: wrap;
      margin-right: 11px;

      > span {
        margin-right: 20px;
      }
    }
  }
}

@media(min-width: 1229px) {
  .mainImage {
    width: 600px;
    height: 411px;
    padding: 70px;
  }
  .DialogTemplate main {
    padding-top: 70px;

    .icon {
      height: 50px;
      padding-bottom: 20px;
    }

    > .title {
      padding-bottom: 18px;
      font-size: 24px;
    }

    article {
      font-size: 14px;
      padding-bottom: 20px;
      padding-right: 50px;
    }

    .feature {
      font-size: 14px;

      .title {
        & > span:before {
          width: 10px;
          height: 10px;
        }
      }

      .content {
        margin-right: 11px;

        > span {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>